.create-board-modal {
  .modalBox {
    .content {
      font-size: 14px;
      line-height: 17px;
    }

    .title {

      h3 {
        font-size: 21px;
        font-weight: 100;
      }

      h6 {
        font-size: 14px;
      }

      .modalClose {
        background-size: 100%;
        color: $light-grey;
        cursor: pointer;
        font-size: 27px;
        position: absolute;
        right: -20px;
        top: -20px;
        height: 16px;
        width: 16px;
      }
    }

    .take-a-tour {
      display: none;
    }

    .create-new-board {
      position: relative;

      input {
        @include textarea-style;
        background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/add.svg');
        background-repeat: no-repeat;
        background-position: 10px;
        border: 2px solid $shale-grey;
        font-size: 14px;
        height: 43px;
        padding: 0 0 0 40px;
      }
      input.enter-board-name.error {
        border-color: $school-bus-yellow;
      }

      .button {
        @include banshee-button($bg: $primary-button-bg-color);
        font-weight: 500;
        height: 43px;
        line-height: 16px;
        margin-top: 12px;
        margin-bottom: 0px;
        padding: 14px 0;
        border-radius: 2px;
        background-color: #02A388;

        @media #{$mobile} {
          margin-top: 12px;
        }
      }
    }
  }
}