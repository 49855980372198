.create-board-modal {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  top: 0;
  position: fixed;
  right: 0;
  z-index: 1000;
  pointer-events: auto;

  &.ng-hide-add,
  &.ng-hide-remove {
    transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-duration: .3s;
    -webkit-transition-duration: .3s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    display: block !important;
  }

  &.ng-hide {
    opacity:0;
  }

  .modalBox {
    background-color: white;
    left: 50%;
    margin: 0 auto auto;
    position: fixed;
    right: auto;
    bottom: auto;
    text-align: center;
    padding: 24px 24px;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 450px;

    &.ng-hide {
      margin-top: -2%;
    }

    @media #{$mobile} {
      height: 100%;
      width: 100%;
      margin-top: 0;

      .content, .enter-board-name, .button {
        width: 80%;
        margin: auto;
      }
    }

    @media #{$medium-up} {
      border-radius: 2px;
      height: auto;
      padding: 30px 45px;
    }

    &.ng-hide-add,
    &.ng-hide-remove {
      transition-property: margin-top;
      -webkit-transition-property: margin-top;
      transition-duration: .3s;
      -webkit-transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      display: block !important;
    }

    .title {
      h3 {
        padding: 0 50px;
      }
      .modalClose {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_white.svg);
      }
    }

    .content {
      margin: 0 auto;
      padding: 10px 0;
      color: #333;
    }

    .create-new-board {
      display: inline;
      margin: 0 auto;
      position: relative;
      & > * {
        margin-top: 12px;
      }


      a.button {
        display: block;
        box-shadow: inset 0 0 1px 2px rgba(255,255,255,0.2);
        font-size: 16px;
        opacity: 1;
        max-width: 80%;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
      }

      input {
        max-width: 80%;
        display: block;
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
      }

      input.ng-invalid ~ a.button {
        cursor: default;
        border: none;
        opacity: .6;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .modalBox {
      .title {
        a.modalClose {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_grey.svg);
          height: 16px;
          width: 16px;
          right: 30px;
          top: 20px;
        }
      }
    }
  }
}