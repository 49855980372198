.add-search-within {
  border-bottom: 1px solid $some-grey-border;
  box-shadow: 0 6px 5px -5px rgba($dark-grey, .05);
  height: 71px;

  span.board-tag {
    position: relative;
    top: -48px;
    left: 100px;
    z-index: 100;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $grid-grey;
    border: 1px solid $light-grey;

      @media #{$mobile} {
        display: none;
      }
  }

  .search-box {
    form {
      background-color: $white;
      display: block;
      height: 100%;
    }
    input {
      background-color: $white;
      color: black;
      height: 70px;
      font-size: 1.25em;
      outline: none;
      padding-left: 210px;

      @media #{$mobile} {
        padding-left: 80px;
      }
    }

    button.search-icon {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/search_icon.svg);
      background-position: 28px 5px;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 60px;
      outline: none;
      top: 0;
      width: 90px;
      font-size: inherit;

      &::before {
        display: none;
      }

      @media #{$mobile} {
        background-position: 10px 5px;
      }
    }

    .search-clear {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/search_clear.svg);
      background-position: 0;
      background-repeat: no-repeat;
      background-size: 55px;
      bottom: 0;
      cursor: pointer;
      display: block;
      position: absolute;
      right: 20px;
      top: 0;
      width: 55px;

      @media #{$mobile} {
        right: 5px;
      }
    }
    .spinner {
      top: 0;
    }
  }
}
