.identify-modal {
  header {
    background-color: white;
    margin-bottom: 30px;

    h3 {
      font-weight: 500;
    }
  }

  .identify {
    margin-bottom: 35px;

    button {
      @extend .primary-cta;
      height: 55px;
    }

    input {
      border: 1px solid $shale-grey;
      box-shadow: none;
      font-size: rem(16);
      font-weight: normal;
      outline: none;
      padding: 10px 20px;
      width: 100%;
    }
  }

  .account-actions {
    border-top: 1px solid $shale-grey;

    a {
      font-weight: 500;
    }
  }

  .modalBox {
    width: 100%;

    @media #{$medium-up} {
      border-radius: 5px;
      height: auto;
      max-width: 600px;
      padding: 30px 45px;
      top: 50px;
    }
  }
}
