.tags-wrapper {
  position: relative;

  ul.suggestions-list {
    border: 1px solid $india_gray;
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 1;
    background-color: white;
    max-height: 172px;
    overflow: scroll;

    li.suggestion {
      color: $charlie_gray;
      border-bottom: 1px solid $india_gray;
      padding: 1em;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover, &.active {
        color: white;
        background-color: $delta_gray;
      }
    }
  }
}

.keywords {
  .keyword {
    background-color: $charlie_gray;
    display: flex;
    float: left;
    color: white;
    margin: 0 1em 0.5em 0;
    padding: 0.5em 5em 0.5em 0.5em;
    position: relative;

    .remove-keyword {
      @extend %close-icon;
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);

      &:before {
        cursor: pointer;
      }
    }
  }
}

.curated-search{
  .tags-wrapper {
    background-color: $juliet_gray;

    .input-container {
      height: auto;
      padding: 2em 1.5em;
      display: inline-flex;
      width: 400px;

      .search-input {
        border: none;
        vertical-align: top;
        height: 2em;
        font-size: 1.5em;
        color: $echo_gray;
        font-weight: lighter;
      }

      .search-submit {
        cursor: pointer;
        color: black;
        background-color: $white;
        padding: 0;
        height: 2em;
        width: 2em;
        vertical-align: top;
        @extend %search-icon;
      }
    }

    .suggestions-list {
      width: 400px;
      top: 75%;
      overflow-x: hidden;
    }

    .title-search-toggle {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;

      z-index: 1;
      width: 2em;
      height: 2em;
      border: 1px solid $india_gray;
      outline: none;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:checked:before {
        content: "X";
        color: black;
        font-size: 1.5em;
      }
    }

    .title-search-label {
      padding-left: 2em;
      font-size: 1.2em;
    }

    .filter-by {
      position: absolute;
      display: inline-block;
      right: 1.5em;
      top: 50%;
      transform: translateY(-50%);

      .input-container {
        width: 20em;
        color: $echo_gray;
        font-weight: lighter;
      }

      .filter-type {
        color: $echo_gray;
        font-weight: lighter;
        font-size: 1.5em;
        width: 15rem;
        border: none;
      }
    }

    .previous-input {
      position: absolute;
      left: 1.5em;
      bottom: 0;
      padding-bottom: 2px;

      p {
        display: inline-block;
        margin: 0;

        .input-text {
          color: black;
        }
      }
      .pipe {
        margin: 0 0.5em;
      }

      .clear-input {
        cursor: pointer;
      }
    }
  }

  .keywords {
    padding:0 0.5em;
    overflow: hidden;

    .keyword {
      border-radius: 6px;
      line-height: 1;
      margin: 1.5em 0.5em;
      color: inherit;
      padding-right: 2em;
      background-color: $juliet_gray;

      &.clear-keywords {
        cursor: pointer;
        background-color: $white;
      }
    }
  }
}
