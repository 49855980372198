.board-content header {
  button.curation-status-button,
  button.create-set-button {
  	@include banshee-button($bg: $bravo_primary);

    height: 55px;
    padding: 15px 30px 16px;
    margin: 0 0 0 16px;
  }

  button.create-set-button:after,
  button.curation-status-button:after,
  button.add-image:before {
    height: 22px;
    display: inline-block;
    vertical-align: top;
  }

  button.curation-status-button,
  button.create-set-button {
    display: none;
  }
}
