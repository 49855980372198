@import "shared/legacy/modules/comments";

.board-details {
  margin: 0 0 0 28px;

  aside.board-comments {
    background: transparent;
    padding-top: 0;
    margin-top: 0;
    padding-right: 18px;
    padding-top: 25px;

    .close {
      display: block;
      left: auto;
      right: 18px;
      width: 14px;
      height: 14px;
      top: 0;

      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_grey.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }

}
aside.comments {
  .new-comment-section {
  padding: 0;
  margin: 0;
  button.comment-add {
    @extend .primary-cta;
    margin-top: 15px;
    width: 100%;
    text-transform: none;
    font-size: 18px;
  }
    textarea.new-comment {
      @include textarea-style;
      margin: 0;
      font-size: rem(14);
      width: 100%;
      height: 100px;
    }
  }

  .close {
    color: $grey;
  }
  .comment {
    padding: 0;
    border-bottom: 1px solid rgba(black, .2);
    &:last-child {
      border-bottom: none;
    }
    .delete-comment-icon {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_trash.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .comment + .comment {
    border-top: 1px solid rgba(white, .5);
  }

  .comment-details {
    .comment-created-by {
      color: $grey;
      font-weight: bold;
    }

    .comment-text {
      color: $medium-grey;
      margin-left: 0px;
      width: 100%;
    }

    .comment-created-date {
      color: $light-grey;
      margin-left: 0px;
    }
  }

  .new-comment-section {
    button.comment-add {
      @extend .primary-cta;
      margin-top: 15px;
    }
  }

}
