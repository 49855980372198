.choose-board-modal {
  .modalBox {
    .title {
      color: #0c0d0d;
      font-size: 21px;
    }

    .container {

      .search-container, .save-container {

        input {
          border: 1px solid $light-grey;
          font-size: 14px;
          padding-left: 40px;

          &:focus {
            border: 2px solid $shale-grey;
            padding-left: 39px;
          }
        }
      }

      .search-container {
        ul.board-list {
          .no-board {
            font-size: 14px;
          }

          li.board-item {
            height: 26px;
            background-color: #FFF;
            transition-property: background-color;
            transition-duration: .3s;
            transition-timing-function: cubic-bezier(0.25,0.1,0.25,1);

            &:first-child {
              margin-top: 4px;
            }

            &:hover {
              background-color: #ebf1f5;
              .save-bubble {
                display: block;
                margin-right: 6px;
                opacity: 1;
              }
            }

            .board-name {
              width: 65%;
            }

            .save-bubble {
              color: $green;
              font-size: 12px;
              padding-top: 4px;
              width: 30%;
              opacity: 0;
              margin-right: 2px;
              text-align: right;
              transition-property: margin-right, opacity;
              transition-duration: .15s;
              transition-timing-function: cubic-bezier(0.25,0.1,0.25,1);
            }
          }

          .more-than-hundred-boards {
            background-color: #ebf1f5;
          }
        }
      }

      .save-container {
        input.ng-invalid {
          &~ a.button {
            background-color: #02A388;
            opacity: .6;
          }
        }

        input {
          background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/add.svg');
          background-repeat: no-repeat;
          background-position: 10px;
        }

        input.board-name-box.error {
          border: 2px solid $school-bus-yellow;
        }

        a.button {
          background-color: $green;
          border: 1px solid #02937a;
          line-height: 40px;
          margin-bottom: 16px;
          outline: none;
        }
      }
    }
  }
}