@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

.bulk-download-modal {
  @include bulk_download_modal;

  .modalBox {
    height: 30.5em;
    display: flex;
    padding: 0;
    max-width: 1000px;
    width: 100%;
  }

  .modal-content-main {
    display: flex;
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }

  .flex-center {
    display: flex;
    align-items: center;
  }
  .spinner-oval {
    background-image: url(~spinner-oval.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }

  .left-side {
    flex: auto;
    overflow: auto;
    flex-grow: 2;
    max-width: 398px;
    color: $alpha_gray;

    .agreement {
      font-size: 0.75rem;
      line-height: 14px;
    }

    .select-size {
      margin-top: 24px;
    }

    .select-size__heading {
      font-size: 0.875em;
      margin-bottom: 5px;
      margin-top: 10px;
      line-height: 1.1;
    }

    .select-size__option {
      height: 27px;
      line-height: 27px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    input[type="radio"]{
      display: none;
      + div {
        position: relative;
        width: 20px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &:not(:checked) + div {
        background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/radio_buttons/radio_unselected.svg');
      }
      &:hover + div, &+div:hover {
        background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/radio_buttons/radio_hover.svg');
      }
      &:disabled + div {
        background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/radio_buttons/radio_disabled.svg');
      }
      &:checked + div {
        background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/radio_buttons/radio_selected_green.svg');
      }
      &:checked:disabled + div {
        background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/radio_buttons/radio_selected_disabled.svg');
      }
      ~ .size-label {
        color: $alpha_gray;
        font-size: 0.875em;
        cursor: pointer;

        .size-bytes {
          font-size: 0.875em;
          color: $shale-grey;
        }
      }
    }

    .select-size.disabled {
      .select-size__option {
        cursor: default;
      }

      .select-size__heading {
        color: $border-dark-grey;
      }

      .size-label {
        color: $border-dark-grey;
        cursor: default;

        .size-bytes {
          color: $border-dark-grey;
        }
      }
    }
  }

  .right-side, .left-side {
    padding: 24px;
  }

  .right-side {
    @include checkbox;

    background-color: $bg-dark-grey;
    border-left: 1px solid $border-grey;
    flex-grow: 3;
    flex-flow: column;
    height: 100%;
    overflow: auto;
    //Force a width for IE11 since it needs one for flexbox to work correctly.
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 700px;
    }

    .num-items {
      color: $font-grey-color;
      font-size: 0.875rem;
      padding-bottom: 15px;
      text-align: right;
    }

    li.asset-container {
      background-color: $white;
      border: 1px solid $border-grey;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 24px;
      min-height: fit-content;

      &:last-child { border-bottom: 1px solid $border-grey; }

      p { margin: 0; }

      .asset-info {
        font-size: 0.875rem;

        .size-info {
          color: $shale-grey;
          display: flex;
          padding-top: 5px;
          align-items: center;
        }

        .size-icon {
          align-items: center;
          background-color: $border-dark-grey;
          border: 1px solid $border-dark-grey;
          color: $white;
          display: flex;
          height: 36px;
          text-align: center;
          width: 36px;
          flex-grow: 0;
          flex-shrink: 0;

          span {
            margin: 0 auto;
            line-height: 1;
          }
        }

        .image-type  {
          font-size: 1rem;
          font-weight: $font_weight_medium;
        }

        .px-size {
          align-items: center;
          padding-left: 5px;
          font-size: 14px;
          line-height: 18px;
        }

        .divider {
          color: $border-grey;
          margin: 0 5px;
        }
      }

      .img-thumb {
        align-items: center;
        border: 1px solid $border-dark-grey;
        display: flex;
        height: 96px;
        margin: 0 16px 0 24px;
        width: 96px;

        img {
          margin: 0 auto;
          max-height: 94px;
          max-width: 94px;
        }
      }
    }
  }
}
