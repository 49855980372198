@import "unisporkal-styles/stylesheets/unisporkal_engine/uni-guide/ui_patterns/_modals";
.identify-modal {
  @extend %uni-modal;
  display: none;
  font-weight: 100;

  .identify {
    button {
      width: 100%;
    }
  }

  .account-actions {
    padding-top: 2em;
    text-align: center;

    @include make-row(0);

    h6 {
      padding-bottom: 0.5em;
    }

    .sign-in, .register {
      @include make-sm-column(6, 0);
      padding: 1em;
    }
  }

  .modalBox {
    max-width: 50em;
    padding: 2.5em;
  }
}
