.horiz-mosaic .board-item-image {
  .image {
    display: block;
    img { pointer-events: none; }
  }
}
.favorites-open .board .board-item .play {
  top: auto;
}
.board .horiz-mosaic .board-item .play {
  top: 45%;
}
