@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints";

$boards-add-asset-media-breakpoint: 1224px;
$boards-large_down: media_range($max: $boards-add-asset-media-breakpoint);

.board .toolbar {
  padding-top: 0.5em;
  height: 50px;
  position: relative;

  .add-search {
    position: absolute;
    bottom: 40px;
    right: 20px;

    @media #{$boards-large_down} {
      position: relative;
      bottom: 12px;
      right: 0px;
    }

    form {
      position: relative;
      min-width: 200px;

      .spinner {
        top: 8px;
        left: 8px;
      }

      input.search {
        height: 36px;
        margin: 0px 5px;
        padding: 8px;
        width: 145px;
        position: relative;

        &:focus {
          outline-style: none;
          box-shadow: none;
          border-color: transparent;

          & ~ button.search-icon {
            color: white;
            background-color: #666666;

            &:hover, &:active {
              color: #666666;
              background-color: white;
            }
          }
        }

        //THIS IS ALL IN HERE BECAUSE OF THE ISSUE
        // WITH NG-INVALID&NG-UNTOUCHED NOT WORKING AS IT'S SUPPOSED TO
        &.ng-untouched {
          &~ button.search-icon {
            &:hover, &:active {
              color: white;
              background-color: #ccc;
            }
          }
        }
      }

      button.search-icon {
        height: 36px;
        background-color: #ccc; //$light-grey seems to break this in srp
        font-size: 1em;
        padding: 1em 0.5em;
        width: auto;
        vertical-align: top;
        transition: background-color .15s ease;

        &:hover, &:active {
          color: #666666;
          background-color: white;
        }
      }

      @media #{$mobile, $tablet} {
        display: none;
      }
    }
  }

  button.outline, a.outline {
    background: none;

    border: 1px solid darken($white, 20%);
    color: $white;
    cursor: pointer;
    font-size: 24px;
    font-weight: 200;
    margin-left: 7px;
    padding: 0;
    vertical-align: middle;

    height: 30px;
    width: 30px;
    position: relative;

    &:before {
      position: absolute;
    }

    transition: all 0.25s;

    &:hover {
      border: 1px solid darken($white, 40%);
      background-color: $india_gray;
      color: $alpha_gray;
    }

    &[disabled], &.disabled {
      color: $charlie_gray;
      border-color: $charlie_gray;

      &:hover {
        background: none;
        border-color: $charlie_gray;
      }
    }

    &:before {
      font-size: 36px;
    }
  }

  .board-share-button {
    text-align: right;
    float: left;
  }

  .share {
    background-color: $echo_gray;
    color: $white;
    margin-left: 7px;
    float: left;
    font-size: rem(14);
    font-weight: 300;
    min-width: 60px;
    padding: 9.5px 15px 11px 20px;
    text-transform: uppercase;

    i.download-icon {
      content: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/getty/icon_menu_shared.svg);
      height: 20px;
      margin: 0em 0.7em -0.4em -0.5em;
      width: 20px;
    }

    &:hover {
      background-color: $white;
      color: $echo_gray;

      i.download-icon {
        content: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/getty/Share_onlight.svg);
      }
    }

    @media #{$desktop} {
      display: inline-block;
    }
  }

  .board-comments-button {
    text-align: right;
    float: left;
    margin: 0 8px;
    text-transform: cap;

    button.board-comments {
      background-color: $echo_gray;
      min-width: 60px;
      padding: 0 5px;

      &:hover {
        background-color: $white;
      }
    }
  }

  button.board-comments:hover  {
    border: none;
  }

  .board-view-options {
    float: left;
    font-size: 125%;

    input { display: none; }

    &:last-child {
      border-right: none;
    }

    label {
      color: $delta_gray;
      padding: 8px;
      height: 40px;

      &:before { font-size: 25px !important; }
    }

    label.grid {
      border-right: 1px solid $charlie_gray;
      padding-right: 1em;
      margin-right: 0.5em;
    }

    label.vert-mosaic {
      html.no-csscolumns & { display: none; }
    }

    input:checked + label {
      color: $white;
    }
  }

  .board-selection-options {
    float: left;

    .select-all-assets,
    .clear-selection {
      border-right: 1px solid $delta_gray;
      padding-right: 8px;

      @media #{$tablet} { border-right: none; }
    }
    .clear-selection {
      margin-left: 8px;
    }
    .board-asset-count {
      margin-top: 11px;
      display: inline-block;
      & > span {
        padding: 0 1em;
      }

      .asset-count {
        display: inline-block;
        text-align: center;
        line-height: 1.1;

        border-left: 1px solid $delta_gray;
        border-right: 1px solid $delta_gray;
      }

      .total-asset-count {
        position: relative;
      }

      .selected-asset-count {
        position: relative;
        display: block !important; // override ng-hide
      }

      @media #{$tablet} {
        display: inline-block;
        text-align: center;
      }
    }
  }

  span.board-actions {
    whitespace: no-wrap;
    margin-left: 5px;

    .callout {
      margin-right: 5px;
    }

    @media #{$tablet} {
      display: block;
      padding-left: 0.5em;
      margin-top: 0.75em;
    }

  }

  span.board-pager {
    display: inline-block;

    @media screen and (min-width: 960px) {
      float: right;
    }
  }

  button.move-to-cart {
    &:before {
      left: -1px;
      bottom: -2px;
      font-size: 32px;
    }
  }

  button.clear-all {
    &:before {
      left: 1px;
      bottom: -2px;
      font-size: 32px;
    }
  }

  button.print-contact-sheet {
    &:before {
      left: -2px;
      bottom: -2px;
      font-size: 32px;
    }
  }

  .more-actions {
    position: relative;
    text-align: left;

    *[disabled] { pointer-events: none; }

    a {
      color: $india_gray;
      background-color: transparent;

      cursor: pointer;
      &:hover, &:focus { text-decoration: none; }
      &[disabled], &.disabled { color: $charlie_gray; }
    }

    & > a {

      color: $india_gray;
      font-family: $primary_font_family;
      font-size: 13px;
      font-weight: normal;

      padding: 5px 4px 0 12px;
      width: auto;
      text-transform: none;

      &:after {
        font-size: 24px;
      }
    }

    span:focus + ul, &.child-focused > ul, li.child-focused > ul, &.child-hover > ul, li.child-hover > ul {
      visibility: visible;
    }

    ul {
      visibility: hidden;
      position: absolute;
      top: 2em;
      left: 0.5em;
      z-index: 120; // hover above delete icons at index 1000
      width: 100%;

      padding: 0.5em 0;
      border: 1px solid $bravo_gray;
      color: $india_gray;
      background-color: black;

      a {
        display: inline-block;

        height: 100%;
        width: calc(100% + 2em);

        margin: 0 -1em;
        padding: 0.5em 1em;
      }

      ul {
        left: 100%;
        top: -0.5em;
        max-height: 60vh;
        overflow-y: scroll;
        width: auto;
      }
    }

    .menu {
      &:after {
        position: absolute;
        right: 0;
      }
    }

    li {
      &:hover {
        color: $alpha_gray;
        background-color: $india_gray;
        a, button { color: inherit; }
        a:hover { text-decoration: none; }
      }
    }

    li, h1 {
      position: relative;
      padding: 0em 1em;
    }

    h1 {
      font-size: 100%;
      color: $echo_gray;
      margin-top: 0.75em;
      padding: 0.5em 1em;
    }

    .new-board {
      font-weight: bold;
    }
  }

  .show-details {
    position: relative;
    display: inline;
    float: left;

    label { font-size: 100%; }

    .onoffswitch {
      top: -3px;
      position: absolute;
    }

    .text {
      margin-left: 40px;
    }

    label.onoffswitch-label {
      margin-bottom: -0.5rem;
    }

    label.text-label {
      margin-left: 0.75em;
      color: $india_gray;
      cursor: pointer;
    }

    .radio-button {
      display: block;
      position: relative;
      float: left;

      input[type=radio]{
        position: absolute;
        visibility: hidden;
      }

      label {
        display: block;
        position: relative;
        padding: 1px 10px 0px 55px;
        margin: 10px auto;
        height: 30px;
        z-index: 9;
        cursor: pointer;
      }

      label{
        color: $india_gray;
      }

      .check {
        display: block;
        position: absolute;
        border: 2px solid #AAAAAA;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        top: 8px;
        left: 20px;
        z-index: 5;
      }

      .check {
        border: 2px solid #FFFFFF;
      }

      .check::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 100%;
        height: 11px;
        width: 11px;
        top: 5px;
        left: 5px;
        margin: auto;
      }

      input[type=radio]:checked ~ .check {
        border: 2px solid $bravo_primary;
      }

      input[type=radio]:checked ~ .check::before{
        background: $bravo_primary;
      }
    }
  }

  .diag-modal {
    position: fixed;
    z-index: 1000;
    background-color: white;
    padding: 2em;
    border: 1em solid black;
    width: 80%;

    top: 50%;
    left: 50%;
    margin: 0;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    .modalClose {
      @extend %close-icon;
      position: absolute;
      right: 10px;
      top: 10px;
      color: transparent;

      &:before {
        color: $delta_gray;
        font-weight: 600;
        font-size: 30px;
      }

      &:target {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      }
    }

    textarea {
      width: 100%;
      height: 10em;
    }
  }
}
