.favorites-open, .boards_container {
  .open-board, .back-button {
    @extend .secondary-cta;

    &::after {
      display: none;
    }

    &:hover {
      background-color: $shale-grey;
    }

    position: absolute;
    right: 15px;
    width: 160px;
    margin: 0;
    padding-top: 8px;
    background-color: transparent;
    color: $light-grey;
  }
}
