.board, .panel {
  .close {
    position: fixed;
    top: 0;
    right: 0;
    color: $white;
    font-size: 1.5em;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }

  button, .button {
    height: 40px;
  }
}
