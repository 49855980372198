@import "getty/legacy/print_search_results";

@media print {
  body {
    //keep site header logo, but hide the rest
    #site-header nav, #site-header input {
      display: none;
    }
    #site-header #logo{
      &:after{
        clear:both;
      }
    }

    aside{
      display:none;
    }

    .board {
      background-color: white;

      .board-content {
        article {
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          min-height: 0px;
        }
      }

      .asset-grid.grid{
        .board-item{
          //reduce margins to make it easier to fit multiple columns of images on a printed page
          margin: 5px;
          .selection-comments{
            display:none;
          }
        }
      }

      .board-content .board-details{
        .asset-grid.vert-mosaic, .asset-grid.horiz-mosaic{
          display: none !important;
        }
        .asset-grid.grid{
          display: block !important;
        }
      }
    }

    header {
      display: none;
    }

    .board-operations {
      display: none;
    }

    .toolbar {
      display: none;
    }

    #footer {
      display: none;
    }

  }
}