@media print {
  //when board is open, hide the search results. We want to print the board only.
  body.search_container.board-open{
    .content_wrapper{
      @media #{$mobile} {
        overflow: hidden;
      }
      .main_body, aside{
        display: none;
      }
    }
  }

  .board.panel, .board.overlay{
    position: relative;
  }
}
