@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";

$global-transition-timing: 0.5s;
$global-transition-easing: ease-in-out;

$favorites-height: 0;
$favorites-height-plus-overflow-safety: $favorites-height + 40px;
$empty-favorites-margin: 32px 10px;

$panel-offset: 85px;
$panel-offset-medium-down: 65px;

$board-height: 100%;

$favorites-transition: initial;

$board-actions-transition: opacity #{$global-transition-timing} #{$global-transition-easing};

$panel-color: $grey;

$remove-asset-icon-size: 1em;

@mixin textarea-style {
  border: 1px solid $shale-grey;
  box-shadow: none;
  font-size: 16px;
  height: 60px;
  outline: none;
  padding: 18px;

  &:focus {
    border-width: 2px;
  }
}
