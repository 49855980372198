.add-search-within {
  .search-box {
    input {
      width: 100%;
      &.spinner_applied + button {
        visibility: hidden;
      }
    }
    .search-clear {
      display: none;
    }
  }

  button.search-icon {
    @extend %search-icon;
    position: absolute;
    background-color: transparent;
    font-size: 3em;
  }

  .spinner {
    right: 56px;
    // margin-top: 18px;
    top: -15px;
  }

  form {
    margin: 0;
    display: inline;
    position: relative;
  }
}
