aside.modal.alt-caption-modal {
  @extend %uni-modal;
  display: none;

  font-family: $heading_font_family;

  div.modalBox {
    padding: 2.5em;
    background-color: $panel-color;
    top: 60px;

    a.modalClose:after {
      color: white;
    }
  }

  .alt-caption-content {
    padding: 15px;
    background-color: $panel-color;

    .title {
      color: #999;
      font-weight: bold;
      font-size: 13px;
      padding-bottom: 1px;
    }
    .content-item {
      color: white;
      padding-bottom: 15px;
    }

    .asset-row {
      @include make-row(0);
      padding-bottom: 20px;

      .asset-image {
        @include make-sm-column(3, 0);
        .asset-image-content {
          padding-right: 20px;
          font-size: 10px;
          color: #999;

          img {
            height: 200px;
            margin: 0 auto;
            width: 100%;
            object-fit: contain;
          }

          .asset-missing-message {
            margin: 0 auto;
            width: 100%;
            height: 125px;
            color: grey;
            font-size: 12px;
            background: transparent;
            line-height: 1.0;
            text-align: center;
            .title {
                color: white;
                padding-top: 15px;
                font-size: 20px;
            }
            .subtitle {
              padding-top: 10px
            }
          }

          .asset-info {
            padding-top: 5px;
            .license-type {
              color: white;
              background-color: $charlie_gray;
              border: black 1px solid;
              font-weight: bold;
              padding: 3px;
            }

            .asset-family {
              margin-left: 5px;
              margin-right: 5px;
            }

            .asset-id {
              float: right;
            }
          }
        }
      }

      .asset-caption {
        @include make-sm-column(9, 0);
        .asset-caption-content {
          padding-left: 20px;
        }
      }
    }

    .asset-details-row {
      @include make-row(0);

      .asset-details {
        @include make-sm-column(3, 0);
        .asset-details-content {
          padding-right: 20px;
        }
      }
      .alt-caption-form {
        @include make-sm-column(9, 0);

        form {
          margin: 0px;
        }
        .alt-caption-form-content {
          padding-left: 20px;

          textarea {
            width: 100%;
            height: 140px;
            background-color: #D8D8D8;
            color: $panel-color;
            padding: 10px;
            font-size: 14px;
          }

          .button-bar {
            padding-top: 10px;

            button {
              width: 49%;
              line-height: 0px;
            }
            .secondary {
              float: left;
              color: $panel-color;
              background-color: white;
            }
            .primary {
              float: right;
            }
          }
        }
      }
    }
  }
}
