$comments-width: 363px;

.asset-modal {
  position: fixed;
  overflow-y: auto;

  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparentize($india_gray, 0.05);
  z-index: 1000;

  article {
    width: calc(100% - #{$comments-width});
    float: left;
    text-align: center;
    position: absolute;
    padding: 4em;
    overflow: hidden;

    top: 50%;
    transform: translateY(-50%);

    .content {
      opacity: 1;
      transition: opacity 0.1s;
      height: 100%;

      &.loading {
        opacity: 0;
      }
    }
    .comp {
      position: relative;
      .play {
        opacity: 1;

        position: absolute;
        bottom: 50%;
        margin-bottom: -14px;
        right: 50%;
        margin-right: -12px;
        background: transparentize($alpha_gray, 0.5);
        font-size: 1.5em;
        text-align: center;
        line-height: 1;
        height: 28px;
        width: 24px;
        color: $white;
        cursor: pointer;
        text-decoration: none;

        z-index: 1000;
        &:before {
          font-size: 28px;
        }
      }
    }
    .comp.missing {
      top: 50%;
      color: black;
      .title {
        font-weight: bold;
      }
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 65px;
    width: 26px;
    height: 26px;
    padding-left: 1px;
    line-height: 1;
    color: white;
    z-index: 1000;
  }

  aside.comments {
    padding: 50px 0;
    display: block !important;
    width: $comments-width;
    min-height: 100%;
    float: right;

    .close { display: none; }
  }

  .metadata {
    margin-top: 0;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 40em;

    color: $alpha_gray;

    .title {
      margin: 0.5em 0;
    }
    .license-type, .artist {
      font-weight: bold;
      margin-right: 2em;
    }
  }

  .nav-arrows {
    .prev-asset, .next-asset {
      top: 50%;
      transform: translateY(-50%);

      position: absolute;
      font-size: 400%;
      cursor: pointer;
    }
  }


  @media #{$tablet} {
    article {
      width: 100%;
      float: none;
      text-align: center;
      position: relative;
      transform: none;
    }

    aside.comments {
      float: none;
      width: 100%;
    }

    .nav-arrows {
      .prev-asset, .next-asset {
        top: 4em;
        transform: none;
      }
    }
  }
}
