.board-content header {
  @extend %collaboration-header;

  .close-button {
    @extend %board-overlay-actions-container;
  }

  .title {
    color: $india_gray;
  }

  .status-button-container {
    @media #{$mobile} {
      width: 172px;
      margin: 0;
      margin: 0 auto;
      height: 50px;
    }
  }

  .button-container {
    display: inline-block;
    @media #{$mobile} {
      display: block;
      width: 172px;
      margin: 0 auto;
      height: 50px;
    }
  }

  button.create-set-button, button.curation-status-button {
    float: left;
    width: 12em;
    margin: 0px 30px 0 1em;
    padding: 0;
    @media #{$tablet} { width: 172px; }
    @media #{$mobile} { margin-left: 0px; float: none; margin-bottom: 30px;}

    background-color: transparent;
    border: 1px solid $white;
    text-transform: none;

  }

  button.create-set-button, button.curation-status-button {
    @extend %arrow-down-icon-after;
    padding-top: 5px;
    float: left;
    width: 15em;
    background-color: $bravo_color;
    color: white;
    border: none;
    text-align: left;
    padding-left: 1em;
    @media #{$mobile} {
      margin-top: 10px;
    }

    @media #{$tablet} {
      width: 172px;
      margin: 0px;
    }

    &:after {
      float: right;
      padding-right: 0.1em;
    }

    span.curated-set-unpublished {
      @media #{$tablet} {
        font-size: 12px;
      }
    }
  }

  .panel-visible-button {
    margin-left: 120px !important;
    @media #{$tablet} {
      margin-left: 172px !important;
    }

    @media #{$mobile} {
      margin-left: 0px !important;
    }
  }


  form {
    margin: 0;
    display: inline;
    position: relative;
  }
}
