$background-color: #f7f7f7;
$screen-break-point: 720px;

.board-management {

  section {
    width: 100%;

    .content {
      margin: 0 auto;
      float: none;
    }
  }

  section.tab {
    background-color: $charlie_gray;
    color: $white;
    height: 4em;
    font-size: 14px;

    li {
      cursor: pointer;
      float: left;
      line-height: 2em;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.active {
        background-color: $background-color;
        color: $charlie_gray;
      }

      &:last-child {
        float: right;
      }

      .board-count, .set-count {
        font-size: 24px;
        position: relative;
        top: 0.25em;
        height: 28px;
      }

      .plus {
        top: 0;
        background: transparent;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  section.board-sort-by {
    border-bottom: 1px solid $india_gray;
    padding: 0 12px;

    select {
      border: none;
      height: 3em;
    }
  }

  section.column-names {
    .up {
      @extend %chevron-up-icon;
    }

    .down {
      @extend %chevron-down-icon;
    }

    .board-name-column, .last-updated-date-column {
      cursor: pointer;
    }
  }

  // Mobile view
  @media screen and (max-width: $screen-break-point) {
    section {
      width: auto;
    }

    section.tab {
      li {
        width: 33%;
        line-height: 2em;
        border-right: 1px solid $background-color;

        &:last-child {
          border: none;
        }
      }

      .hidden-tab {
        @media #{$tablet} {
          display: none;
        }
      }

      .mobile {
        line-height: 0.9em;
      }

      .desktop {
        display: none;
      }
    }

    section.column-names {
      display: none;
    }

    section.board-list {
      .board-item {
        height: 160px;
        padding: 0 20px;

        .board-thumbnail {
          width: 100px;
          height: 100px;
          top: 30px;
          line-height: 99px;

          & img {
            max-height: 100px;

            &.no-thumbnail {
              width: 100px;
              height: 100px;
            }
          }
        }

        .board-summary {
          line-height: 20px;
          padding: 27px 0 0 20px;
          width: 70%;

          .board-name {
            padding-bottom: 5px;

            .text {
              max-width: 77%;
            }
          }

          .rename-board {
            top: 27px;
          }

          .board-image-count, .board-comments-count {
            text-transform: lowercase;

            & span:before {
              content: " ";
            }
          }
        }

        &.swipe-to-left {
          width: 120%;
          left: -20%;
        }

        .delete-board {
          float: right;
          padding-top: 60px;
          color: $white;
          background-color: $delta_color;
          width: 3em;
          height: 100%;
          text-align: center;
          border-left: 1px solid $india_gray;
        }

        .share-board {
          line-height: 160px;
          right: 16px;
        }
      }
    }
  }

  // Desktop or Tablet view
  @media screen and (min-width: $screen-break-point) {
    section.board-sort-by {
      display: none;
    }

    section.tab {

      li {
        width: 12em;

        &:last-child {
          line-height: 4em;
        }

        .plus {
          font-size: 16px;
        }
      }

      .mobile {
        display: none;
      }
    }

    section.column-names {
      display: block;
      background-color: $background-color;
      border-bottom: 1px solid $india_gray;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
      font-weight: bold;
      color: black;

      .content {
        padding-left: 16px;

        div {
          float: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    section.board-list {
      background-color: $kilo_gray;

      .content {
        .board-item {
          height: 152px;
          padding: 0 16px;

          &:hover {
            background-color: $background-color;
          }

          .board-thumbnail {
            width: 120px;
            height: 120px;
            top: 16px;
            line-height: 119px;

            .offline-asset {
              background-repeat: no-repeat;
              background-position: center;
              background-color: $echo_gray;
              height: 100%;
              width: 100%;
            }

            .offline-itna {
              background-image: url('~itna.svg');
              background-size: 52px;
            }

            .offline-bba {
              background-image: url('~bbc.svg');
              background-size: 90%;
            }

            .offline-nbc {
              background-image: url('~nbc.svg');
              background-size: 90%;
            }

            & img {
              max-height: 120px;

              &.no-thumbnail {
                width: 120px;
                height: 120px;
              }
            }
          }

          .board-summary {
            padding: 36px 0 0 20px;
            position: absolute;
            left: 136px;
            width: 80%;

            & > div {
              float: left;
              height: 80px;
              line-height: 80px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .board-name {
              // width: 25%;
              width: 32%;

              .text {
                max-width: 80%;
              }
            }

            .last-updated-date {
              width: 25.5%;
            }

            .owner {
              width: 20%;
            }

            .board-image-count, .board-comments-count {
              width: 10%;

              & span {
                display: none;
              }
            }

            .rename-board {
              top: 60px;
              left: 20px;
            }
          }

          .share-board {
            line-height: 152px;
            right: 36px;
          }

          &:hover {
            .delete-board-desktop {
              display: block;
            }
          }
        }
      }
    }

    .board-thumbnail-column {
      width: 140px;
    }

    .board-name-column {
      width: 25%;

    }

    .last-updated-date-column {
      width: 20%;
    }

    .owner-column {
      width: 16%;
    }

    .board-image-count-column {
      width: 8%;
    }
  }

  section.board-list {

    .board-item {
      border-bottom: 1px solid $india_gray;
      position: relative;

      .board-thumbnail {
        background-color: $kilo_gray;
        float: left;
        cursor: pointer;
        position: relative;
        text-align: center;

        & img {
          vertical-align: middle;
        }
      }

      .board-summary {
        float: left;
        color: black;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        form {
          margin: 0;
        }

        .board-name {
          font-size: 16px;
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          .text {
            float: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: black;
            text-decoration: none;
          }

          .pencil {
            @extend %edit-icon;
            cursor: pointer;
            color: $india_gray;
            font-weight: normal;
            padding-left: 0.5em;
            position: relative;
            bottom: 3px;
          }
        }

        .rename-board {
          position: absolute;

          & input {
            font-size: 18px;
            width: 80%;
            height: auto;

            @media screen and (min-width: 1100px) {
              width: 100%;
            }
          }
        }

        .board-image-count {
          color: $button_color;
          cursor: pointer;
        }
      }

      .share-board {
        @extend %social-share-icon;
        cursor: pointer;
        float: right;
        font-size : 24px;
        color: black;
        position: absolute;
      }

      .delete-board, .delete-board-desktop {
        @extend %close-icon;
        font-size: 24px;
        position: absolute;
        right: 0;
        cursor: pointer;
        z-index: 10;
      }

      .delete-board-desktop {
        display: none;
      }
    }
  }
}
