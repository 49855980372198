@import "unisporkal-styles/stylesheets/unisporkal_engine/uni-guide/ui_patterns/_modals";

$border: 1px solid #ADB9BA;

.choose-board-modal {
  @extend %uni-modal;
  font-weight: 100;
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  top: 0 !important;
  right: 0;
  position: fixed;
  visibility: visible !important;

  &.ng-hide-add,
  &.ng-hide-remove {
    transition-property: opacity;
    -webkit-transition-property: opacity;
    transition-duration: .3s;
    -webkit-transition-duration: .3s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    display: block !important;
  }

  &.ng-hide {
    opacity:0;
  }

  .modalBox {
    height: 300px;
    width: 450px;
    border-radius: 2px;
    left: 50%;
    top: 50%;
    margin: 0 auto auto;
    opacity: 1;
    padding: 24px;
    position: fixed;
    transform: translate(-50%, -50%);

    &.ng-hide {
      margin-top: -2%;
      opacity: 0;
    }

    &.ng-hide-add,
    &.ng-hide-remove {
      transition-property: margin-top;
      -webkit-transition-property: margin-top;
      transition-duration: .3s;
      -webkit-transition-duration: .3s;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      display: block !important;
    }

    &.save-to-new-board {
      min-height: 215px;
    }

    .modalClose {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_white.svg);
      background-size: 100%;
      cursor: pointer;
      font-size: 27px;
      position: absolute;
      right: -20px;
      top: -20px;
      height: 16px;
      width: 16px;

      &::after {
        display: none;
      }
    }

    .title {
      text-align: center;
      padding-bottom: 24px;
    }

    .container {
      display: flex;

      .thumbnail {
        width: 136px;
        height: 120px;
        border: $border;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          max-height: 100%;
        }
      }

      .search-container, .save-container {
        text-align: center;
        width: 250px;

        input {
          width: 250px;
          height: 43px;
          outline: none;
          margin-bottom: 8px;

          &:invalid {
            box-shadow: none;
          }
        }

        a {
          cursor: pointer;
        }

        ul {
          margin-bottom: 14px;
          text-align: left;

          .no-board {
            padding: 4px 12px;
          }
        }
      }

      .search-container {
        input {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/search.svg);
          background-repeat: no-repeat;
          background-position: 10px;
          background-size: 18px 20px;
        }

        ul.board-list {
          width: 250px;
          height: 125px;
          border: $border;
          overflow-y: auto;

          li.board-item {
            &:hover {
              cursor: pointer;

              .save-bubble {
                display: block;
              }
            }

            .board-name {
              margin-left: 8px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .save-bubble {
              position: absolute;
              right: 0;
              top: 0;
              height: 22px;
            }
          }

          .more-than-hundred-boards {
            padding: 8px;
          }
        }
      }

      .save-container {
        a.button {
          border-radius: 2px;
          color: white;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          position: relative;
          text-align: center;
          text-decoration: none;
          width: 250px;
          height: 43px;
          -webkit-appearance: initial;
        }
      }
    }
  }
}