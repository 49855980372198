@-webkit-keyframes fadein {
  0% { opacity: 0; }
  20% { opacity: 0; }
  40% { opacity: 0.3; }
  60% { opacity: 0.5; }
  80% { opacity: 0.9; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  20% { opacity: 0.9; }
  40% { opacity: 0.5; }
  60% { opacity: 0.3; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes fadein {
  0% { opacity: 0; }
  20% { opacity: 0; }
  40% { opacity: 0.3; }
  60% { opacity: 0.5; }
  80% { opacity: 0.9; }
  100% { opacity: 1; }
}

@keyframes fadeout {
  0% { opacity: 1; }
  20% { opacity: 0.9; }
  40% { opacity: 0.5; }
  60% { opacity: 0.3; }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

.board-details {
  @include make-row(0);
  margin: 0 0 0 13px;

  aside.board-comments {
    @include make-xs-column(12,0);
    @include make-sm-column(12,0);
    @include make-md-column(6,0);
    @include make-lg-column(4,0);
    @include make-xl-column(3,0);

    // fix for column includes above
    @media (min-width: 0) {
      float: left;
    }

    margin-top: 7px;
    margin-right: 10px;
    animation: fadein 0.5s;
    display: block;
  }

  aside.board-comments.board-comments-closed {
    animation: fadeout 0.5s;
    display: none;
  }

  .asset-grid {
    @include make-xs-column(12,0);
    @include make-sm-column(12,0);
    @include make-md-column(6,0);
    @include make-lg-column(8,0);
    @include make-xl-column(9,0);

    min-height: initial; //Chrome has problem with bootstrap setting min-height=1px
  }

  .asset-grid.board-comments-closed {
    @include make-xs-column(12,0);
    @include make-sm-column(12,0);
    @include make-md-column(12,0);
    @include make-lg-column(12,0);
    @include make-xl-column(12,0);

    min-height: initial; //Chrome has problem with bootstrap setting min-height=1px
  }
}

aside.comments {
  padding: 25px 25px 0;
  width: 33%;
  font-size: 1.5em;

  .close {
    font-size: 18px;
    position: absolute;
    left: 0;
  }

  .comment {
    margin: 0;
    padding: 0 25px;
    position: relative;
    display: table;
    width: 100%;

    .comment-details {
      float: none;
      display: table-cell;
      vertical-align: top;
      padding: 10px 10px 10px 0;
      max-width: 150px;

      &.swipe-to-left {
        width: 60%;
      }
    }

    .delete-mobile-view {
      float: none;
      display: table-cell;
      vertical-align: top;
      width: 40%;
      display: none;
      vertical-align: middle;
      text-align: center;
      text-transform: uppercase;

      @media #{$mobile} {
        display: table-cell;
      }
    }

    .comment-created-by {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .comment-created-date {
      font-size: 11px;
      line-height: 16px;
      margin-left: 3rem;
    }

    .comment-text {
      font-size: 12px;
      line-height: 18px;
      margin-left: 3rem;
      width: 80%;
      word-wrap: break-word;
    }

    .delete-comment-icon {
      cursor: pointer;
      font-size: 22px;
      float: none;
      display: table-cell;
      width: 10%;
      text-align: right;
      vertical-align: middle;

      @media #{$mobile} {
        display: none;
      }
    }
  }

  .new-comment-section {
    margin: 0 0 1.5em 0;
    padding: 0 25px;
    width: 100%;

    textarea.new-comment {
      border: none;
      display: block;
      font-size: 1rem;
      height: 60px;
      line-height: 1.5rem;
      margin: 0.5em 0 0;
      padding: 10px 20px;
      resize: none;
    }

    button.comment-add {
      font-size: 1em;
      padding-bottom: inherit;
      padding-left: 1em;
      padding-right: 1em;
      padding-top: inherit;
      text-transform: uppercase;
    }
  }
}

button.board-comments {
  border: none;
  font-size: 18px;
  font-weight: normal;
  width: auto;
  transition: all 0.25s;

  &:before {
    font-size: 36px;
  }
}
