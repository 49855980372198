@import "shared/legacy/modules/asset_modal";

.board .board-content .board-details {
  aside.asset-modal {
    background: rgba(black, .85);
    top: 0;
    .close {
      color: $grey;
      height: 14px;
      right: 20px;
      text-align: right;
      top: 16px;
      width: 14px;

      &:before {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_lightgrey.svg);
        background-size: contain;
        content: " ";
        display: inline-block;
        height: 14px;
        margin: 0;
        width: 14px;
      }
      @media #{$tablet} {
        right: 40px;
        &:before {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_lightgrey.svg);
        }
      }
    }
    article {
     .comp .play {
        background: white;
        width: 28px;
        &:before {
          content: " ";
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon-play.svg);
          background-size: 14px;
          background-repeat: no-repeat;
          background-position: center;
          height: 100%;
          display: block;
          width: 100%;
        }
      }
      .comp.missing,
      .comp.missing .title {
        color: white;
      }
    }

    aside.comments {
      background-color: $grid-grey;
      padding: 50px 20px 20px 20px;
    }
    div.metadata {
      background: none;
      color: white;
      font-size: rem(14);
      font-weight: 100;
      margin-top: 5px;

      .asset-id {
        font-weight: 100;
        float: none;
        &:before { content: '#'; }
        @media #{$large-up} {
          float: left;
        }
      }
      .artist {
        display: block;
        font-weight: 100;
        float: none;
        margin-right: 0;
        @media #{$large-up} {
          display: inline-block;
          float: right;
          margin-left: 95px;
        }
      }
      .license-type {
        font-weight: 100;
        float: none;
        margin-right: 20px;
        @media #{$large-up} {
          float: left;
        }
      }
      .title, .collection { display: none; }
    }

    .nav-arrows {
      .prev-asset, .next-asset {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 28px auto;
        color: white;
        height: 96px;
        position: absolute;
        top: 40%;
        width: 50px;
        @media #{$large-up} {
          top: 50%;
        }
      }
      .prev-asset {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/pagination-back_arrow_default.svg);
        left: 10px;
        @media #{$large-up} {
          left: 30px;
        }
      }
      .next-asset {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/pagination-next_arrow_default.svg);
        right: 10px;
        @media #{$large-up} {
          right: 30px;
        }
      }
    }
    &.has-comments {
      .comments-icon .icon:before {
        color: inherit;
      }
    }
    img { max-height: 500px; }
  }
}
