@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";
@import "unisporkal-styles/stylesheets/unisporkal/getty/base/_icons";


.multi-downloads-modal {
  @include bulk_download_modal;

  text-align: center;

  .download-modal__download-link-label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
  }

  .multi-downloads {
    margin-bottom: 25px;
  }

  .download-modal__download-link {
    font-size: 14px;
    font-weight: 400;
  }

  .black-checkmark {
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin: 0 5px;
  }
}