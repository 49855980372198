@page  {
  size: US-letter;
  margin: 0;
}

.contact_sheet_container {
  #header-wrapper {
    display: none;
  }

  .site-width {
    width: 650px;
  }

  .board-contact-sheet {
    font-family: $primary_font_family;
    color: #333333;

    .print {
      text-align: right;

      .print-button {
        width: 20%;
        line-height: 0;
      }
    }

    @media print {
      .print {
        display: none;
      }
    }

    .header {
      width: 100%;
      padding-top: 20px;

      .site-logo {
        @extend %logo-icon;
        font-size: 24px;
      }

      .last-updated-date {
        float: right;
        position: relative;
        top: 15px;
      }
    }

    @media print {
      .header {
        .site-logo {
          .last-updated-date {
            float: right;
            padding-top: 8px;
          }
        }
      }
    }

    .title {
      padding-top: 50px;
      font-weight: 200;
      line-height: initial;

      .last-updated-date {
        font-size: 16px;
        line-height: 32px;
      }

      .board-name {
        font-size: 48px;
      }
      .board-info {
        display: block;

        .created-by, .number-of-assets, .separator {
          display: inline-block;
          font-size: 20px;
          padding-top: 25px;
          padding-bottom: 10px;
        }

        .separator {
          padding: 0 10px;
        }
      }

      .board-description {
        font-size: 18px;
        font-style: italic;
        display: block;
        padding-bottom: 5px;
      }

      .view-read-only {
        padding-right: 5px;
        font-size: 14px;
        display: inline-block;
      }

    }

    .assets {
      margin-top: 40px;
      margin-left: -15px;
      float: none;

      @media screen {
        .header {
          display: none;
        }
      }

      @media print {
        .header {
          padding-top: 20px;
          position: relative;
          display: block;
          float: none;
          clear: both;
          page-break-before: always;
          page-break-inside: avoid;
        }
      }

      .header {
        padding-top: 40px;
      }

      .asset {
        display: block;
        float: left;
        width: 140px;
        line-height: 175px;
        position: relative;
        margin: 0 12px;
        text-align: center;
        padding-bottom: 50px;
        height: 300px;

        img {
          max-width: 100%;
          max-height: 160px;
          vertical-align: middle;

          &.no-img {
            width: 140px;
            height: 140px;
          }
        }

        .missing-img-text {
          position: absolute;
          top: 0;
          width: 140px;
          height: 100px;
          vertical-align: middle;
          line-height: 160px;
          text-align: center;
          left: 0;
          padding-top: 25px;

          .title {
            font-size: 18px;
          }

          .message {
            font-size: 12px;
          }
        }

        .asset-info {
          font-weight: bold;
          text-align: left;
          font-size: 12px;

          .asset-id {
            float: right;
          }
        }

        .asset-alt-caption {
          max-height: 90px;
          text-align: left;
          text-overflow: ellipsis;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }

        .agreement_description {
          color: #4CB7FF;
          font-size: 12px;
          font-weight: 200;
          padding-top: 10px;
          text-align: left;
        }

        & div {
          line-height: normal;
        }
      }
    }
  }
}
