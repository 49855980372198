.share-modal {
  text-align: center;

  &.modal { top: 0; }

  h3 {
    font-size: 21px;
    font-weight: 100;
  }

  h6 {
    font-size: 14px;
  }

  .modalBox {
    border-radius: 4px;
    padding: 40px 60px;
    min-width: 400px;
    width: 50%;

    @media #{$mobile} {
      border-radius: 0px;
      padding: 40px 30px;
      min-width: 0;
      width: 100%;
    }

    .modalClose {
      background-image: url("~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_grey.svg");
      background-size: 100%;
      top: 20px;
      right: 20px;
      height: 18px;
      width: 18px;
      &:before {
        content: "";
      }

      @media #{$medium-up} {
        background-image: url("~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_white.svg");
        right: -25px;
        top: -25px;
      }
    }
  }

  .invite {
    border-bottom: none;
  }

  input[type=text] {
    border: 1px solid $shale-grey;
    outline: none;
    padding: 20px;
    width: 100%;
  }

  .primary-cta {
    margin-bottom: 0;
  }
}
