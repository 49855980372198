$board-toolbar-background: #f2f5f5;
$link-hover-color: #f2f5f5;

.actions-modal-button {
  background-color: $board-toolbar-background;
  border: none;
  color: $shale-grey;
  font-size: 14px;
  margin-right: 10px;
  padding: 8px 12px;

  &[disabled],
  &.disabled {
    background-color: $board-toolbar-background;
    color: $light-grey;
    cursor: default;

    &:hover {
      background: $board-toolbar-background;
      color: $light-grey;
      cursor: default;
    }
  }
}

.more-actions-modal {
  .modalBox {
    border-radius: 4px;
    max-height: 100%;

    @media #{$medium-up} {
      width: 540px;
    }
  }
  h1 {
    font-size: 24px;
  }

  .option-container {
    border: 1px solid $light-grey;
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid $light-grey;
    }

    h2 {
      color: $dark-grey;
      font-size: 18px;
    }

   html:not(.touchevents) &:hover {
      background-color: $link-hover-color;
    }

    .new-board {
      html:not(.touchevents) &:hover {
        background-color: $link-hover-color;
      }
    }
  }

  .board-list {
    h3 {
      color: $some-grey-border;
      font-size: 12px;
      text-transform: uppercase;
    }
    li {
      html:not(.touchevents) &:hover {
        background-color: $link-hover-color;
      }
    }
  }
}
