.board-content {

  @media #{$mobile} {
    min-height: 100%;
  }

  .side-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto;

    .save-buttons {
      bottom: auto;
      button {
        @extend .primary-cta;
      }
    }
  }
}
