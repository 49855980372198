@import "shared/legacy/modules/board";

.boards_container {
  position: relative;
  background: inherit;
}

.board {
  background-color: $grid-grey;
  color: inherit;
  header {
    background-color: $grey;
  }

  @media #{$mobile} {
    .board-content {
      min-height: 100%;
    }
  }
  .boards-zero-search-within, .null-board, .deleted-board {
    margin-top: 80px;
    padding: 20px;
    text-align: center;
    @media #{$mobile} {
      margin-top: 20px;
    }
    .subheader { padding-top: 10px; }
    h4 { font-size: 21px; }
  }
  .null-board, .deleted-board {
    margin-top: 200px;
    @media #{$mobile} {
      margin-top: 80px;
    }
  }
}

.board .board-content .board-article .board-pager {
  @extend %pagination-istock;
  float: none;
  padding-left: 0;
  padding-right: 0;

  .next, .previous {
    > a[name="previous"] .cheveron:before,
    > a[name="next"] .cheveron:after {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/pagination-next_arrow_default.svg) !important;
      &:hover > a[name="previous"] .cheveron:before,
      &:hover > a[name="next"] .cheveron:after {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/pagination-next_arrow_hover.svg);
      }
    }
  }

  @media #{$mobile} {
    margin: 0;
    margin-top: 40px;
  }

  .disabled {
    display: none;
  }

  .total-asset-count {
    display: none;
  }
}

.panel .board-content .board-article .board-pager {
  height: 200px;

  @media #{$mobile} {
    height: 350px;
    margin-top: 0;
    .total-asset-count {
      margin-top: 0px;
      position: relative;
    }
  }
}

.board-content .board-article .toolbar .board-pager {
  width: auto;
  padding-top: 6px;
  margin: 0px;
  float: right;
  .total-asset-count {
    display: initial;
  }
  .board-paging {
    display: none;
  }
}
