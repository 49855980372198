@import "shared/legacy/modules/board_toolbar";

$board-toolbar-background: #f2f5f5;
$board-toolbar-selected-background: white;
$board-toolbar-margin: 10px;
$board-toolbar-color: $shale-grey;

%board-toolbar-button-style {
  height: 35px;
  display: inline-block;
  background: $board-toolbar-background;
  vertical-align: top;
  border-radius: 2px;
  border: none;
  padding: 8px 12px;
  line-height: 19px;
  outline: none;
  user-select: none;
  font-size: 14px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.board .board-content .board-article .toolbar {
  padding-top: 8px;
  padding-bottom: 8px;

  > * {
    vertical-align: top;
  }

  span.board-pager {
    height: 50px;
    margin: 0;
    float: right;
    width: 100px;
    display: inline-block;

    .total-asset-count {
      left: 0;
      right: 0;
      font-size: 14px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin: 0;
      display: inline-block;
      width: 100px;
      color: $shale-grey;
    }
    .board-paging {
      display: none;
    }
  }

  .bulk-download-button {
    float: left;
    margin-right: 1em;
    padding: 0.41rem 1rem;
    font-size: rem(10.5);
    display: none;

    @media #{$desktop} {
      display: inline-block;
    }

    i.download-icon {
      content: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/download-on-dark.svg);
      width: 20px;
      height: 20px;
      margin: 0em 0.7em -0.4em -0.5em;
    }
  }

  .board-share-button {
    color: #333333;

    button.share {
      @extend %board-toolbar-button-style;
      color: $board-toolbar-color;
      background: $board-toolbar-background;
      font-size: 14px;
      transition: none;
      font-weight: 500;

      &:before {
        content: " ";
        display: inline-block;
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_team.svg);
        background-repeat: no-repeat;
        width: 18px;
        height: 17px;
        top: auto;
        left: auto;
        margin-right: 6px;
        vertical-align: bottom;
      }
    }
  }

  .board-comments-button {
    margin: 0;
    button.board-comments {
      @extend %board-toolbar-button-style;
      color: $board-toolbar-color;
      background: $board-toolbar-background;
      font-size: 14px;
      transition: none;
      font-weight: 500;

      &:hover,
      &.active {
        background: $board-toolbar-background;
      }
      &.comments-open {
        background: $board-toolbar-selected-background;
      }
    }
  }

  .board-selection-options {
    display: inline-block;
    text-align: left;
    .show-details {
      .text {
        display: inline-block;
        margin: 0;
      }
      .onoffswitch {
        .onoffswitch-inner {
          &:before {
            background-color: $green;
          }
        }
      }
      .onoffswitch,
      label.onoffswitch-label {
        display: none;
      }
      label.text-label {
        color: $board-toolbar-color;
        @extend %board-toolbar-button-style;
        margin-left: 0;
        margin-right: $board-toolbar-margin;
      }
      &.details-shown {
        label.text-label {
          background-color: $board-toolbar-selected-background;
        }
      }
    }

    .board-asset-count {
      margin-top: 0px;
      display: initial;
    }

    .asset-count {
      border: none;
      @extend %board-toolbar-button-style;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -2px;
      padding-right: 0;
      .selected-asset-count {
        color: $board-toolbar-color;
        border-right: 1px solid $board-toolbar-color;
        padding-right: 12px;
      }
    }

    .select-all-assets {
      cursor: pointer;
    }

    .clear-selection, .select-all-assets {
      margin-left: 0px;
    }

    button.clear-all:disabled {
      cursor: default;
    }

    .select-all-assets,
    .clear-selection {
      @extend %board-toolbar-button-style;

      &.select-all-assets:before {
        content: " ";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 4px;
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_select_all.svg);
        vertical-align: top;
      }
      span {
        color: $board-toolbar-color;
      }

      a {
        color: $board-toolbar-color;
      }
    }

    > span {
      padding: 0;
      margin-top: 0;
    }
  }

  .board-view-options {
    margin: 0;
    font-size: inherit;
    label {
      @extend %board-toolbar-button-style;
      color: $board-toolbar-color;
      &.grid,
      &.horiz-mosaic {
        padding: 7px 12px 6px;
        border: none;
        &:before {
          content: " ";
          display: block;
          width: 17px;
          height: 17px;
          margin-top: 2px;
        }
      }
      &.grid {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0;
        &:before {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_grid_view.svg);
        }
      }
      &.horiz-mosaic {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-right: $board-toolbar-margin;
        &:before {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_mosaic_view.svg);
        }
      }
    }
    input:checked + label {
      color: $light-grey;
      background: $board-toolbar-selected-background;
    }
  }

  span.board-actions {
    display: inline-block;

    button.move-to-cart,
    button.print-contact-sheet {
      display: none;
    }
    button.outline,
    a.outline {
      @extend %board-toolbar-button-style;
      width: auto;
      transition: none;
      color: $board-toolbar-color;

      &[disabled],
      &.disabled {
        color: $light-grey;

        &:hover {
          background: $board-toolbar-background;
        }
        &:before {
          opacity: .5;
        }
        &:after {
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-open-lightgrey.svg);
        }
      }
    }

    .more-actions {
      > a:after {
        content: " ";
        width: 13px;
        height: 7px;
        background-position: center center;
        display: inline-block;
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets-open.svg);

        padding: 14px 0 0 6px;
        vertical-align: top;
        margin-left: 8px;
      }
      &.child-focused a.outline {
        background: $board-toolbar-selected-background;
      }
      h1 {
        padding-left: 13px;
        padding-right: 13px;
        text-transform: uppercase;
        font-size: 10px;
        color: $some-grey-border;
      }
      ul {
        border: none;
        background-color: $board-toolbar-selected-background;
        font-size: 13px;
        top: 30px;
        left: 0;
        color: $board-toolbar-color;
        li {
          background: transparent;
        }
        a {
          color: $board-toolbar-color;
          background-color: $board-toolbar-selected-background;
          font-weight: normal;
          transition: none;
          &:hover {
            background-color: $board-toolbar-background;
          }
          &.disabled {
            opacity: .5;
          }
        }
        ul {
          top: 0;
          transition: all .25s ease-in;
          left: 100%;
          min-width: 100%;

          @media #{$mobile} {
            left: 0;
            position: relative;
            margin: 0;
          }
        }
      }

      // 'Choose an option' dropdown (mobile)
      &.child-focused { // dropdown open
        @media #{$mobile} {
          ul li {
            a, h1 {
              margin-top: 0;
              visibility: visible;
            }
          }
          > ul {
            overflow-y: scroll;
            max-height: 200px;
          }
          ul li { // dropdown options shown
            &.child-focused {
              ul {
                height: 100%;
              }
            }
          }
          .menu {
            font-weight: bold;
            &::after {
              display: none;
            }
          }
          .bulk-download a { font-weight: bold; }
        }
      }
      .menu:after {
        content: " ";
        width: 16px;
        height: 18px;
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/arrow-bullets.svg);
        right: 6px;
        top: 6px;
      }
    }
    [disabled] button.print-contact-sheet:before,
    [disabled] button.clear-all:before {
      opacity: .5;
    }
  }

  button.outline,
  a.outline {
    margin-left: 0;
    margin-right: 10px;
  }

  button.clear-all:before {
    color: white;
    content: " ";
    background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_trash.svg);
    width: 15px;
    height: 17px;
    display: inline-block;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    vertical-align: top;
  }
}

button.board-comments {
  margin-right: $board-toolbar-margin;
  &:before {
    content: " ";
    display: inline-block;
    background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_comments.svg);
    width: 18px;
    height: 17px;
    content: " ";
    top: auto;
    left: auto;
    margin-right: 6px;
    vertical-align: bottom;
  }
}