@use "sass:math";
@import "unisporkal-styles/stylesheets/unisporkal/shared/hospice/uni-guide/_config";
@import "unisporkal-styles/stylesheets/unisporkal/shared/hospice/uni-guide/_mixins";
@import "unisporkal-styles/stylesheets/unisporkal/shared/hospice/uni-guide/_extends";
$grid-item-width: 320px;
$grid-padding-width: 20px;
$drag-highlight-bar-width: 4px;
$grid-item-info-strip-height: 64px;
$details-height: 15px;

.board {
  .asset-grid {
    margin: 0 -10px;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
  }

  .board-item {
    position: relative;
    break-inside: avoid;

    // Commented out due to https://bugs.webkit.org/show_bug.cgi?id=25633
    // -webkit-column-break-inside: avoid;

    .board-item-image {
      padding: 7px 7px 7px 10px;
      text-align: center;
      border-radius: 3px;
    }

    &:hover {
      .remove {
        opacity: 1;
        // Uncomment this when chrome doesn't have a bug with css columns and transitions.
        // Note: a 2nd chrome bug also exists on video ADP (HTML5 video element behind the panel)
        //        transition: opacity .25s;
        transition: opacity .25s;
      }
    }

    html.touch & .remove, html.touchevents & .remove {
      opacity: 1;
    }

    &:hover {
      .play.previewable {
        display:none;
      }
    }

    .play {
      opacity: .6;

      position: absolute;
      bottom: 50%;
      margin-bottom: 19px;
      right: 50%;
      margin-right: -12px;
      font-size: 1.5em;
      text-align: center;
      line-height: 1;
      height: 28px;
      width: 24px;
      cursor: pointer;
      text-decoration: none;

      top: 127.5px;
      left: 50%;
      transform: translateX(-50%);

      z-index: 300;

      &:before {
        font-size: 28px;
      }
    }

    .remove {
      opacity: 0;

      position: absolute;
      top: 0;
      right: 0;
      margin: 6px;
      font-size: 1.5em;
      text-align: center;
      line-height: 1;
      height: 28px;
      width: 24px;
      cursor: pointer;
      text-decoration: none;

      z-index: 100;

      &:before {
        font-size: 28px;
      }
    }

    .board-item-image.offline {
      position: relative;

      img.board-asset {
        width: 300px;
        height: 300px;
      }

      .offline-message, .asset-missing-message {
        position: absolute;
        top: 7px;
        right: 7px;
        bottom: 11px;
        left: 7px;
        padding: 2em 1em 1em 1em;
      }

      .offline-message {
        line-height: 1.5em;
      }

      .collection {
        margin-bottom: 1em;
      }

      .title {
        margin-bottom: 1em;
      }
    }

    .board-item-image.missing {
      @extend .board-item-image, .offline;
      .title {
        font-weight: bold;
        line-height: 0.5em;
      }

      .asset-missing-message {
        padding-top: 95px;
        line-height: 0;
      }
    }
  }

  .asset-grid.grid {
    .board-item {
      display: inline-block;
      vertical-align: top;
      margin: 10px;
      width: $grid-item-width;
      &[draggable=true]:hover {
        cursor: move;
      }

      @media (max-width: 320px) {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .board-item-content {
      position: relative;
      border-radius: 5px;
      padding: 14px 14px 10px 14px;
      width: $grid-item-width;

      @media (max-width: 320px) {
        width: 100%;
      }

      .board-item-content-edge {
        position: absolute;
        top: 30%;
        right: 10px;
      }
    }

    .board-item-image {
      display: block;
      vertical-align: middle;
      height: $grid-item-width - ($grid-padding-width * 2);
      line-height: $grid-item-width - ($grid-padding-width * 2);
      width: $grid-item-width - $grid-padding-width - 10;
      padding: 0;

      @media (max-width: 320px) {
        width: 100%;
      }
    }

    .board-item-image.offline .offline-message {
      padding: 0;
    }

    img.board-asset, video {
      max-height: calc(100% - 5px);
      max-width: calc(100% - 10px);
      vertical-align: middle;
    }

    video {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .info-strip {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 90%;
      padding-top: 10px;

      .asset-info {
        display: none;
        padding: 5px 0;
        position: relative;
        height: 29px;

        .license-type {
          padding: 0.25em;
          margin-right: 0.5em;
        }

        .asset-id {
          position: absolute;
          right: 0;

          -webkit-touch-callout: text;
          -khtml-user-select: text;
          user-select: text;

          &:hover {
            cursor: text;
          }
        }
      }

      .artist {
        display: none;
        margin-top: 7px;
        text-transform: capitalize;
      }

      .collection {
        display: none;
        margin-bottom: 0.5em;
      }

      .download {
        height: 2em;

        .text {
          padding-top: 0.4em;
          border-top: 1px solid transparent;
        }

        .download-asset {
          &:before {
            @extend %download-icon;
            position: relative;
            bottom: 1px;
            margin-left: -4px;
            font-size: 18px;
          }
          cursor: pointer;
          &.view-only {
            &:before {
              display: none;
            }
            color: #ccc;
            pointer: default;
          }
        }
      }

      .details {
        display: none;

        text-align: left;
        white-space: initial;
        overflow: hidden;
        padding: 0.5em 0;

        .text {
          height: $details-height;
          padding: 0 0.1em;
        }

        .date-created {
          margin-right: 0.75em;
        }

        .title {

        }
      }
    }

    &.show-details {
      .info-strip .asset-info, .details, .artist, .collection { display: block; }
      .has-comments {
        .comments-icon {
          .count { display: none; }
        }
        .comments-preview { display: inline; }
      }
    }

    .asset-alt-caption {
      display: none;

      color: white;
      font-weight: 300;
      font-size: 14px;
      background-color: lighten($bravo_gray, 10%);
      height: 110px;
      margin-bottom: 10px;
      margin-top: 5px;
      cursor: pointer;

      .empty-caption {
        text-align: center;
        line-height: 100px;
        color: $india_gray;
        font-weight: 400;
        font-size: 14px;
      }
      .alt-caption {
        color: $india_gray;
        padding: 10px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        height: 100px;
        font-weight: 400;
        font-size: 14px;
        white-space: pre-wrap;
      }
    }

    &.show-captions {
      .asset-alt-caption {
        display: block;
      }
    }

    .selection-comments {
      border-bottom: none;
      height: 24px;
      padding-top: 8px;

      .prior-download__container {
        float: right;
        margin-right: 28px;
      }

      .checkbox {
        float: right;
        position: relative;
        display: inline-block;
        bottom: 5px;
        right: 18px;

        input[type="checkbox"] {
          display: none;
        }

        input[type="checkbox"] + label {
          display: inline-block;
          position: absolute;
          top: 4px;
          width: 18px;
          height: 18px;
          vertical-align: middle;
          cursor: pointer;
        }

        input[type="checkbox"]:checked + label {


          &:before {
            position: absolute;
            font-size: 17px;
            left: 0px;
            bottom: 0px;
          }
        }
      }

      .comments-icon {
        position: relative;
        text-align: center;
        margin-left: -8px;
        bottom: 6px;

        .icon {
          font-size: 24px;
          width: 36px;

          &:before {
            position: absolute;
            top: -3px;
            left: 0;
            font-size: 36px;
          }
        }

        .count {
          display: inline-block;
          position: absolute;
          left: 20px;
          top: -1px;
          border-radius: 8px;
          min-width: 16px;
        }
      }
    }


    .comments-preview {
      display: none;
      cursor: pointer;
      font-size: 8pt;
      line-height: 1;

      .comment-text {
        max-width: 235px;
        &.has-more { max-width: 190px; }
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .comment-count {
        padding: 0 0.5em;
        margin-left: 0.75em;
        border-radius: 1em;
        overflow: hidden;
      }
    }

    .board-item.compound {
      img.board-asset {
        border-right: 3px solid gray;
        border-bottom: 3px solid gray;
        border-radius: 15px;
      }

      .info-strip {
        .artist, .collection {
          display: none;
        }
        .license-type {
          color: transparent;
        }
      }
    }
  }

  .asset-grid.horiz-mosaic {
    .asset-container {
      text-align: justify;
      @extend %clearfix;
    }

    .board-item {
      position: relative;
      display: inline-block;

      &:hover {
        .editorial-only-notice {
          display: block;
          width: auto;
          height: auto;
          position: absolute;
          font-size: 11px;
          z-index: 200;
          .intended-for-editorial {
            color: black;
            background-color: white;
            padding: 3px 5px;
          }
        }
      }
    }

    img.board-asset {
      height: 300px;
      padding: 0;
    }

    video {
      position: absolute;
      left: 3px;
      right: 0;
      margin: 0 auto;
    }
  }

  .asset-grid.vert-mosaic {
    $column-gap: 6;
    column-width: auto;
    column-count: 2;
    column-gap: em($column-gap);

    img.board-asset {
      width: 100%;
    }
  }

  @media only screen and (min-width : 300px) and (max-width : 599px) {
    .asset-grid.vert-mosaic {
      column-count: 1;
    }
  }

  @for $i from 2 through 10 {
    @media only screen and (min-width : $i * 300px) and (max-width : ($i * 300px) + 299px) {
      .asset-grid.vert-mosaic {
        column-count: $i - 1;
      }
      .asset-grid.vert-mosaic.board-comments-closed {
        column-count: $i;
      }
    }
  }

  .board-item.placeholder {
    opacity: 0;
  }

  // Gap drop targets
  .board-item {
    .gap-left, .gap-right {
      display: none;
      position: absolute;
      top: 0 - math.div($grid-padding-width, 2);
      width: $grid-item-width + $grid-padding-width;
      height: $grid-item-width + $grid-padding-width;
      z-index: 500;

      &.on-drag-enter { display: block; }
    }

    .gap-right {
      right: 0 - math.div($grid-padding-width, 2);
    }
  }
}
