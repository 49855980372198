.board-open .board.overlay {
  overflow: hidden;
  top: 0;

  header {
    height: 175px;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;

    @media #{$mobile} {
      &.hide-search-within {
        height: 218px;
      }
    }

    @media #{$medium-up} {
      height: 105px;
      padding: 32px 15px;
    }
    @media #{$large-up} {
      height: auto;
    }

    a.open-board {
      bottom: 8px;
    }
  }
  .board-article {
    height: 100%;
    margin-top: 105px;
    overflow: auto;
    padding: 0;

    @media #{$mobile} {
      margin-top: 175px;
      &.hide-search-within {
        margin-top: 215px;
      }
    }
  }

  .board-pager {
    @media #{$mobile} {
      height: 320px;
    }
  }
  .board-content {
    min-height: 100%;
  }
}
