.board .board-content article.board-article {

  padding-left: 0;
  padding-right: 0;

  div.metadata {
    padding: 0 28px;
    margin-top: 12px;

    input[type=text], textarea {
      color: $medium-grey;

      &::-webkit-input-placeholder { color: $light-grey;  font-style: italic; }
      &::-moz-placeholder { color: $light-grey;  font-style: italic; }
      &:-ms-input-placeholder { color: $light-grey;  font-style: italic; }
      &:placeholder { color: $light-grey;  font-style: italic; }

      &:focus {
        background-color: $some-grey;
      }
    }

    .description {
      width: 100%;
      font-size: 14px;
    }

    .byline, .board-description {
      margin-left: 0;
    }
    .board-description {
      .show-more-content {
        font-size: 11px;
      }
    }
    .board-created-date {
      margin-left: 0;
      font-size: 11px;
    }
    .byline {
      font-size: 12px;
      color: $light-grey;
    }
  }
}


.board article > section > .metadata {
  .board-name:hover + .pencil:before,
  .board-description:hover + .pencil:before {
    content: ' ';
    display: block;
    background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_edit_pen.svg);
    background-size: 100% 100%;
    width: 12px;
    height: 12px;
    padding-top: 10px;
    position: absolute;
    left: 9px;

    transform: rotateY(180deg);
  }

  input[type=text]:focus,
  input[type=text]:hover,
  textarea:focus,
  textarea:hover {
    background-color: $some-grey;
    outline: none;
  }
  input.board-name {
    margin-left: 0;
  }

  .board-operations {
    right: 28px;

    .delete-board {
      font-size: 14px;
    }
  }
}


.callout-content {
  align-items: center;
  background-color: $some-grey;
  border-radius: 5px;
  border: 1px solid #ccd3d3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto;
  padding: 10px 20px;

  @media #{$mobile} {
    flex-direction: column;
    text-align: center;
  }

  .new-highlight {
    background-color: $red;
    border-radius: 5px;
    color: $white;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 4px;
    text-transform: uppercase;
  }

  .callout-message {
    color: $grey;
    display: inline-block;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
  }
}

