@import "unisporkal-styles/stylesheets/unisporkal_engine/getty/modules/header/_offset";

.board-content .side-panel {
  width: 350px;
  background: $white;
  display: inline-block;

  height: calc(100% - #{$unisporkal-header-height} - #{$superstrip-height});
  html.superstrip-hidden & { height: calc(100% - #{$unisporkal-header-height}); }
  @media #{$tablet} { height: calc(100% - #{$unisporkal-header-height}); }

  position: fixed;
  z-index: 200;
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
  color: black;
  left: 0;
  top: -100vh;
  display: none;

  .preview {
    height: 32px;
    padding: 8px;
    background: black;
    display: flex;
    justify-content: space-between;

    a {
      color: $href_color;
      margin: 0 15px;
    }

    a:hover, a:active {
      text-decoration: none;
    }
  }

  .status {
    @extend %arrow-up-icon-after;
    width: 100%;
    height: 77px;
    background: $bravo_color;
    padding: 25px 20px;
    h5 {
      color: $white;
      font-weight: 100;
      display: inline-block;
    }

    &:after {
      font-size: 2.2em;
      float: right;
      color: $white;
      margin-bottom: 3px;
    }
  }

  .last-updated-message {
    padding: 1em 3em;
    width: 82%;
    margin-left: 9%;
    margin-top: 1em;
    border: 1px solid $hotel_gray;
  }

  .side-panel-section-title {
    border-bottom: 1px solid $bravo_gray;
    width: auto;
    padding-bottom: 8px;
    margin-top: 5px;
    font-size: 1.2em;
  }

  .set-metadata {
    padding: 15px 30px;


    .input-container {
      margin: 15px 0 0 0;

      span {
        @extend %plus-icon;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    input.add-keyword {
      @extend %plus-icon;
      height: 30px;
      font-size: 1em;
      font-weight: 100;
      margin: 0;
    }

    .keywords-required {
      color: white;
      display: inline-block;
      margin: 10px 0px;
    }

    .visible {
      color: red;
    }

    .radio-button:last-child {
      border-bottom: 1px solid lightgrey;
    }

    .radio-buttons {
      padding: 10px;
      margin: 0;
      .radio-button {
        height: 28px;
        padding-top: 5px;
        border-top: 1px solid lightgrey;

        label {
          font-size: .9em;
        }

        input {
          margin: 0px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .alt-caption {
      clear: both;
      width: 100%;
      padding-top: 10px;

      .side-panel-section-title {
        margin: 10px 0 0 0;
      }
      .checkbox {
        padding-top: 15px;

        input[type="checkbox"] {
          display: none;
        }

        input[type="checkbox"] + label {
          display: inline-block;
          width: 25px;
          height: 25px;
          vertical-align: middle;
          cursor: pointer;
          margin-top: 3px;
        }

        input[type="checkbox"]:checked + label {
          &:before {
            position: absolute;
            font-size: 17px;
            left: 0px;
            bottom: 0px;
          }
        }
        input[type="checkbox"] + label {
          border: 1px solid $bravo_gray;
          .check {
            display: none;
          }

        }

        input[type="checkbox"]:checked + label {

          &:before, .check {
              display: block;
              font-size: 20px;
              font-weight: 100;
              text-align: center;
              padding-top: 3px;
          }
        }
      }
      .label {
        margin-left: 5px;
      }
    }

  }

  .publish-schedule {
    padding: 0px 30px;
    span {
      display: block;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    input {
      height: 30px;
      font-weight: 100;
      border: 1px solid $bravo_gray;
      width: 100%;
      padding: 10px;
    }
  }

  .save-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 30px;

    button {
      display: block;
      padding: 5px 20px;
      margin-top: 10px;
      height: 50px;
      width: 100%;
    }

    button.unpublish-button {
      background: $white;
      border: 1px solid $bravo_gray;
      color: $bravo_gray;
    }
  }
}

.board-content .slim-panel {
  display: block;
  @include header-offset(top, 55);

  @media #{$mobile} {
    width: 100%;
    height: calc(100vh - #{$unisporkal-header-height});
    top: calc(-100vh + #{$unisporkal-header-height});
    position: absolute;
  }
}
