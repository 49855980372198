.board-content header {
  //The stylings for approver are meant to be temporary. Should be removed after redesign of the Board search box section is complete.

  @media #{$tablet} {
    height: 100px;
  }

  @media #{$mobile} {
    height: 250px;
    .fixed-actions.approver-actions {
      select.board-selector {
        margin-top: 80px;
      }
    }
  }

  .curated-set {
    display: initial;
  }

  .approver {
    font-size: 1.2rem;
  }

  .add-search.approver {
    font-size: 1rem;
    width: 235px !important;
    @media #{$tablet} {
      width: 172px;
    }

    .approver input.search {
      width: 172px;
      @media #{$tablet} {
        width: 100px;
      }
      @media #{$mobile} {
        width: 172px;
      }
    }
  }

  .search-box.show-search-box.approver input {
    font-size: 1em;
    width: 175px;

    @media #{$tablet} {
      width: 100px;
      margin: 0 0 0 15px;
    }

    @media #{$mobile} {
      margin: 0 0 0 0;
    }
  }

  button.share.approver, button.add-image.approver {
    margin: 0 0 0 1em;

    @media #{$tablet} {
      width: 100px;
    }
    @media #{$mobile} {
      margin: 0 0 10px 0;
      width: 172px;
    }
  }

  button.curation-status-button, button.create-set-button {
    margin-right: 0px;
    background-color: $bravo_color;
    color: white;
    border: none;
  }

  button.invisible-button {
    @media #{$mobile} {
      display: none;
    }
  }
}

.publish-status-modal {
  .modalBox {
    width: 600px;
    height: 230px;

    .title {
      padding: 45px 0 0 0;

      h3 {
        padding-bottom: 0.65em;
      }
    }

    .description {
      min-height: 3.2em;

      h6 {
        padding-bottom: 0.65em;
        color: $echo_gray;
      }
    }

    .error {
      .description {
        font-size: 1.3em;
      }
    }

    .publish {
      display: flex;
    }

    a.button {
      background-color: $delta_color;
      bottom: -3em;
      height: 46px;
      line-height: 46px;
      font-size: 16px;
      position: relative;
      padding: 0;

      &:first-child { margin-right: 5px; }
      &:last-child { margin-left: 5px; }
    }

    input {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 600px) {
    .modalBox {
      .title {
        padding-top: 5em;
      }
    }
  }

  @media screen and (min-width: 800px) {
    .modalBox {
      .title {
        padding-top: 2em;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .modalBox {
      width: 100%;
      height: 100%;
      float: none;
      text-align: center;
      position: relative;
      transform: none;
    }
  }
}
