@mixin page-center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.share-modal {
  @extend %uni-modal;
  display: none;
  
  // resets
  text-transform: initial;
  text-align: left;
  color: $primary_text;
  //

  // Fix for some aliasing issues caused by transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  
  .modalBox {
    max-width: 50em;
    padding-top: 15px;

    .modalClose {
      @extend %close-icon;
      right: 10px;
      top: 10px;
      
      &:before {
        background-color: transparent;
        color: $delta_gray;
        font-weight: 100;
        font-size: 36px;
        width: auto;
      }

      &:after {
        content: '' !important;
        background-color: transparent !important;
      }
    }
  }
  
  h3 { 
    padding-bottom: 0.5em; 
    color: $primary_text;
  }
  
  h6 {
    padding-bottom: 0.5em;
    margin-bottom: 0.3em;
    color: $primary_text;
  }

  input[type=text] {
    font-size: 1em;
  }

  .invite {
    border-bottom: 1px solid $delta_gray;
    padding-bottom: 2em;
    margin-bottom: 1.5em;
    
    .button {
      height: auto;
      text-transform: none;
    }
  }
}

.asset_detail_container.elite .share-modal {
  h3, h6 {
    color: $primary_text;    
  }
}