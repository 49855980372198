.actions-modal-button {
  display: inline-block;
  font-weight: normal;
  height: auto !important; // clean up
  outline: none;
  position: relative;
  text-transform: none;
  top: 1px;
  width: auto;

  &[disabled], &.disabled {
    background: none;
    border-color: $charlie_gray;
    color: $charlie_gray;

    &:hover {
      background: none;
      border-color: $charlie_gray;
      color: $charlie_gray;
    }
  }
}

.more-actions-modal {
  @extend %uni-modal;
  &.modal {
    top: 0;
  }

  .modalBox {
    overflow-y: auto;
    // for overlay panel which is not getting
    // general modal styles
    padding: 0.625em 1.25em 1.25em;

    @media #{$medium-up} {
      margin-bottom: 50px;
    }
  }

  h1 {
    outline: none;
    padding: 20px;
    text-align: center;
  }

  .option-container {
    border: 1px solid black;
    border-bottom: none;
    cursor: pointer;
    &:last-child {
      border-bottom: 1px solid black;
    }
    h2 {
      padding: 20px;
    }
    .board-list h3, .board-list li {
      display: block;
      padding: 3px 20px;
    }
    .new-board {
      display: block;
      margin-top: -5px;
      margin-bottom: 10px;
      padding: 10px 20px;
    }
  }

  .options-list {
    margin: 0;
    overflow: hidden;
    transition: height  0.25s ease-in-out 0s;
  }

  .option-list-container {
    display: block;
  }

  .option-active {
    &:hover {
      background-color: white !important;
    }

    .options-list {
      height: 100%;
    }
  }

  .board-list {
    padding-bottom: 20px;

    a {
      display: block;
    }
  }
}
