$background-color: #f7f7f7;
$screen-break-point: 500px;

section.set-list-headers, section.set-list-footers {
  display: block;
  background-color: $background-color;
  vertical-align: middle;
  font-weight: bold;
  color: black;
  height: 40px;
  line-height:40px;
  border: 1px solid $india_gray;

  @media screen and (max-width: 500px) {
    height: initial;
    line-height: initial;
  }

  .set-sort-by {
    float: left;
    width: 50%;
    height: 100%;
    padding-left: 16px;

    @media screen and (max-width: 500px) {
      float: none;
      width: auto;
      height: 50px;
      line-height: 40px;
      font-size: 16px;
      padding-top: 4px;
      padding: 4px 20px 0px 20px;
    }

    .sort-by {
      font-weight: 200;
      margin: 0px 10px;
    }

    .dropdown-container {
      @extend %chevron-down-icon;
      width: 150px;
      font-weight: 200;
      color:black;
      margin: 0;
      height: 100%;
      border-radius: 0px;
      padding-top: 0px;
      border: none;
      box-shadow: none;
      background-color: $background-color;

      @media screen and (max-width: 500px) {
        padding-bottom: 0px;
        float: right;
        width: 200px;
      }

      &:before {
        float: right;
        margin-top: 10px;
      }

      ul.dropdown {
        color: black;
        box-shadow: none;

        li.hide-me {
          display: none;
        }

        a {
          color: black;
          font-weight: 200;
          padding-left: 25px;
        }
      }
    }
  }

  .set-management-paging {
    width: 50%;
    height: 40px;
    line-height: 36px;
    float: right;
    font-weight: 200;
    padding-right: 16px;

    @media screen and (max-width: 500px) {
      height: 100%;
      float: none;
      width: 100%;
      background-color: white;
      font-size: 18px;
      padding-top: 15px;
      height: 70px;
      text-align: center;
    }

    input {
      background-color: $background-color;
      @media screen and (max-width: 500px) {
        background-color: white;
      }
    }
  }
}

section.set-list-footers {
  border-bottom: none;
}
