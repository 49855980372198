// iStock specific styles for board management page
.board-management {
  background-color: $grid-grey;
  padding-bottom: 100px;

  section.board-sort-by {
    padding: 0;
    select {
      border-radius: 0px;
      appearance:none;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      background-color: $grey;
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/arrow_down.svg);
      background-position: 100% 50%;
      background-repeat: no-repeat;
      color: white;
      font-size: 14px;
      padding: 0px 10px;
      width: 100%;
    }
  }
  @media #{$tablet} {
    .create-board-tab .mobile div { line-height: 56px; }
  }

  h2 {
    display: none;
    margin: 50px 100px 20px 100px;
    padding: 0;
    width: auto;

    @media #{$large-up} {
      display: block;
    }
  }

  .board-item-container-ref {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  section.tab { // my boards row
    background-color: rgba(102,110,110,0.2);
    color: $grey;

    li {
      border-right: 1px solid $shale-grey;
      color: $shale-grey;

      &:last-child {
        border-right: none;
      }

      &.active {
        background-color: transparent;
        color: $dark-grey;
        font-weight: 500;
      }

      &:last-child {
        border-right: none;
      }

      .plus { display: none; }

      @media #{$large-up} {
        border: none;
      }

      @media #{$mobile} {
        font-size: 12px;
        line-height: 28px;
      }
    }
  }

  section.column-names, section.board-list {
    margin: 0;
    width: auto;

    @media #{$large-up} {
      margin-left: 100px;
      margin-right: 100px;
    }
  }

  section.column-names {
    background-color: $grey;
    color: white;
    font-weight: 100;
    overflow: hidden;

    .board-thumbnail-column { opacity: 0; }

    .down {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/arrow_down.svg);
      background-repeat: no-repeat;
      cursor: pointer;
      height: 20px;
      width: 25px;
      &:before { content: none; }
    }
    .up {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/arrow_up.svg);
      background-repeat: no-repeat;
      cursor: pointer;
      height: 20px;
      width: 30px;
      &:before { content: none; }
    }
  }

  section.board-list {
    padding: 0;
    background-color: inherit;

    ul { margin: 0; }

    .content .board-item {
      background: $white;
      border: none;
      border-bottom: 1px solid $light-grey;

      @media #{$large-up} {
        border: 1px solid $light-grey;
        border-top: none;
      }

      .board-summary {
        @media #{$mobile} {
          width: 55%;
        }
      }

      .board-name {
        font-weight: 500;
      }

      .delete-board, .delete-board-desktop {
        background-color: $light-grey;
        color: $shale-grey;
        z-index: 2;

        @media #{$medium-up} {
          background-color: transparent;
          display: block;
        }
      }

      .delete-board-desktop {
        background-color: transparent;
      }

      .board-thumbnail {
        background-color: transparent;
      }

      .board-image-count a { color: black; }

      .pencil {
        z-index: 2;
      }

      span.rename-board {
        z-index: 220;

        form input {
          height: 30px;
        }
      }

      .share-board {
        background-image: url("~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_share.svg");
        background-repeat: no-repeat;
        cursor: pointer;
        height: 20px;
        margin-left: 10px;
        position: absolute;
        top: 65px;
        width: 20px;
        z-index: 2;
        &:after { content: ''; }
      }
    }
  }

  // Pagination
  .paging {
    @extend %pagination-istock;

    background-color: $grid-grey;
    margin: 0;
    padding-bottom: 60px;
    padding-top: 50px;

    &:first-child { display: none; }
    .board-management-paging, .set-management-paging {
      text-align: center;

      &.hidden-pager {
        display: none;
      }
    }
    .filtered_board_count { display: none; }
  }
}
