@use "sass:math";
$base-font-size: 1em;

@function em($px, $base: $base-font-size) {
  @return (math.div($px, $base)) * 1em;
}

//HACK: Prevents background scrolling on mobile view when modal is open
body.modalActive {
  @media #{$mobile} {
    position: fixed;
  }
}

.boards_container {
  background: $alpha_gray;
  .site-width{
    width: auto;
    max-width: none;
  }
}

.board {
  background: $alpha_gray;
  color: $india_gray;
  position: relative;

  .board-content {
    min-height: 800px;
    width: 100%;
    height: 100%;
    min-width: 330px;
    @media #{$mobile} {
      min-width: 100px;
    }

    .panel-links {
      display: inline-block;
      width: 25vw;
      float: left;
      padding: 0;
      margin-right: 15px;
    }

    .preview {
      height: 33px;
      padding: 8px;
      background: black;
      color: $white;
    }

    .status {
      height: 72px;
      background: $bravo_color;
      padding: 25px;

      h5 {
        color: $white;
        font-weight: 100;
      }
    }

    .panel-container {
      display: flex;
    }

    header {
      background-color: $panel-color;
      padding: 32px 15px;
      @media #{$mobile} { padding: 35px 15px 15px 15px; }
    }

    button.close {
      height: 35px;
      font-size: 1.3em;
      font-weight: bolder;
      margin-left: 0;
    }

    .link-widget {
      vertical-align: top;
    }

    .board-article {
      overflow-anchor: none; // Chrome Scroll Anchoring feature seems to be automatically scrolling page to the bottom after making a AJAX call. (https://github.com/angular-ui/ui-scroll/issues/138)

      min-height: 480px;
      height: calc(100% - 104px); // minus height of panel
      padding-bottom: 100px;

      .board-pager {
        float: right;
      }
    }

    .slim-article {
      margin-left: 350px;

      @media #{$mobile} {
        margin-left: 0px;
      }
    }

    article.loaded {
      opacity: 1;
      transition: opacity 2500ms;
    }
  }

  .mobile-board-panel {
    @media #{$mobile} {
      margin-top: calc(100vh - 51px);
    }
  }

  .deleted-board, .null-board {
    text-align: center;
  }
}
