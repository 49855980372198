// TO DO: move colors to config once styleguide updated
$bg-dark-grey: #ebf1f5;
$font-grey-color: #535B5B; // new?
$border-grey: #c5d2d2;
$border-dark-grey: #adb9ba;

@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_radio_buttons";
@include radio-button-component;

@mixin bulk_download_modal {
  @extend %uni-modal;
  background: transparentize($alpha_gray, 0.15);

  &.active{
    display: flex;
    flex-flow:column;
  }

  .modalBox {
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-flow: column;
    margin: auto auto;
    max-width: 32em;
    overflow: visible;
    padding: 24px;

    .message {
      font-size: 0.875rem;
      line-height: 20px;
      width: 100%;

    }

    .center {
      text-align: center;
    }

    .message.info2, .message.error {
      margin-top: 10px;
    }

    .message.error {
      border: solid 1px $border-grey;
      border-radius: 2px;
      padding: 0.3rem 0.4rem;
      display: flex
    }

    .error-icon {
      background: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/exclamation-mark.svg) no-repeat;
      background-position: center;
      font-size: 0.8rem;
      font-weight: normal;
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      flex-shrink: 0
    }

    .primary-cta {
      margin: 24px 0;
      padding: 12px 24px;
    }
    .download-complete-button {
      display: none;
      font-size: rem(10.5);
      height: 45px;
      width: 200px;

      @media #{$desktop} {
        display: inline-block;
      }
      .checkmark-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 200px;
      }
      img {
        width: 60px;
      }
    }
  }

  a.modalClose {
    right: -2em;
    top: -1.6em;
    &::after { color: $medium-light-grey; }
  }

  h3 {
    padding-bottom: 16px;
    text-align: center;
  }

  .selection {
    height: 360px;
    width: 460px;
    hr {
      margin: 0;
    }

    .company-icon {
      background-image: url(~company.svg);
      background-repeat: no-repeat;
      background-size: contain;
      height: 40px;
      margin-right: 22px;
      width: 40px;
    }

    .choices {
      width: 90%;
    }

    .choice-container {
      align-items: center;
      border-top: 1px solid $grid-grey;
      border-bottom: 1px solid $grid-grey;
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      height: 90px;
      width: 100%;
      label {
        cursor: pointer;
        margin: 10px 0;

        .choice {
          font-size: 16px;
          margin-right: 15px;

          strong {
            font-weight: 500;
          }
        }
      }

      &.selected {
        background-color: $extra-light-grey;
        border-bottom: 1px solid $hr-border-color;
        border-top: 1px solid $hr-border-color;
      }
    }
  }

}

.unsupported-files-modal, .download-complete-modal, .multi-downloads-modal, .bulk-download-team-credit-modal, .zero-remaining-files-modal, .personal-selector-modal {
  @include bulk_download_modal;
}

.unsupported-files-modal {
  p {
    margin-bottom: 1.3em;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    text-align: left;
    padding-left: 3rem;
    margin-bottom: 1rem;
  }

  .message {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.zero-remaining-files-modal .message { padding-bottom: 20px; }

.bulk-dl {
  position: relative;
}
