.board.overlay {
  left: 0;

  visibility: hidden;
  opacity:0;

  overflow: hidden;
  position: fixed;
  width: 100%;

  // specific closing board transition
  transition: height     0.5s ease-in-out 0s,
              opacity    0.7s ease-in-out 0s,
              visibility 0s   ease-in-out 0.5s;

  height: $favorites-height;
  top: $panel-offset;

  .board-open & {
    height: $board-height;
    visibility: visible;
    opacity: 1;
    overflow: auto;

    // opening board transition
    // fast-forward the opacity fade-in so as to not come in from pure white
    // against the existing black panel (that just looks weird)
    transition: height  0.5s ease-in-out 0s,
                opacity 0.9s ease-in-out -0.3s;

    @media #{$mobile} {
      overflow-x: hidden;
    }
  }

  .board-item {
    .remove {
      opacity: 1;
      @media #{$large-up} {
        opacity: 0;
      }
    }
  }
}

body.board-open { overflow: hidden; }

