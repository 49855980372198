.board article > section > .metadata {
  padding: 1em 0 0 0.5em;

  .board-name:hover + .pencil, .board-description:hover + .pencil {
    &:before {
      content: '\270e';
      font-family: "Lucida Grande","Arial Unicode MS", sans-serif;
      position: absolute;
      left: 6px;
      font-size: 20px;
    }
  }

  .board-name:focus + .pencil:before {
    content: '' !important;
  }

  .board-name[disabled] + .pencil:before {
    content: '' !important;
  }

  &.child-focused .pencil:before {
    content: '' !important;
  }

  .board-description:hover + .pencil {
    &:before {
      margin-top: -33px;
    }
  }

  input[type=text], textarea {
    display: block;
    padding: 0;
    height: auto;
    border: none;
    text-overflow: ellipsis;
    background-color: transparent;
    overflow: hidden;
    resize: none;
  }

  input[type=text], textarea {
    font-size: 100%;

    &[disabled=disabled] {
      &:focus, &:hover {
        background-color: transparent;
      }
    }
  }

  input.board-name {
    width: 30em;
    height: 1.5em;
    line-height: 1.5em;
    margin-left: 6px;
    font-size: 20px;
    float: left;

    @media #{$tablet} {
      width: 250px;
    }
    @media #{$mobile} {
      width: auto;
    }
  }

  .showing-more, .child-focused {
    div.description {
      height: auto;
    }

    textarea.description {
      height: auto;
      @media #{$tablet} { height: 5.1em; }
      @media #{$mobile} { height: 8.2em; }
    }
  }

  .board-description {
    @include make-row(0);

    & > span {
      @include make-xs-column(10,0);
      @include make-sm-column(10,0);
      @include make-md-column(10,0);
      @include make-lg-column(10,0);

      width: 100%;
      position: relative;
      overflow: hidden;
      font-size: 100%;
      line-height: normal;
    }

    margin-left: 6px;
  }

  .board-operations {
    float: right;
    padding: 0.3em;
    position: relative;
    margin-right: 20px;

    .delete-board {
      @media #{$mobile} {
        display: none;
      }
    }
  }

  .byline {
    clear: both;
    margin-bottom: 0.5em;
    margin-left: 6px;
  }

  .board-created-date {
    margin-left: 6px;
  }
}