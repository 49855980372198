@import "unisporkal-styles/stylesheets/unisporkal/shared/hospice/uni-guide/ui_patterns/forms/_input_select";
@import "unisporkal-styles/stylesheets/unisporkal/shared/hospice/uni-guide/_buttons";

//common layout for the Favorites container, and the header of the Board overlay.
//Allows the Board overlay header to drop exactly in front of the Favorites panel for a smooth transition
%collaboration-header {
  @include make-row(0);
  left: 0;
  width: 100%;
  min-height: $favorites-height;
  padding: 0;
}

%board-overlay-actions-container{
  width: 375px;
  position: absolute;
  right: 0;
  top: 0;

  text-align: center;
  padding-right: 0;
  display: block;
}

.panel, .board {
  .fixed-actions {
    height: 100px;

    @media #{$tablet}, #{$mobile} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .board-selector {
    position: absolute;
    top: 35px;
    right: 14px;
    width: 175px;

    border: 1px solid $bravo_gray;
    background-color: $alpha_gray;
    color: $india_gray;

    option[value='string:new'], option[value='string:all'], option.current{
      font-weight: bolder;
      padding-bottom: 10px;
    }

    option[value=''] {
      font-style: italic;
    }
  }

  .open-board, .back-button {
    @extend %tertiary-button;

    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: $light-blue;

    &::after {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 21px !important; // IE9 fix
    }

    // keep the button the same width when showing 'open' or 'close'
    width: 175px;

    padding:{
      left: 15px;
      right: 15px;
      top: 11px;
      bottom: 0;
    }
    vertical-align: middle;
    line-height: 1.1em;

    // it's really easy to select the text on this button because of the nature of the interaction.
    // this button doesn't contain text anyone would want to select, so prevent text selection.
    user-select: none;
    cursor:pointer;

    transition: 0.2s;

    .board-open & {
      @extend %arrow-up-icon-after;
    }
  }
}

.panel .board-selector, .board .select-above {
  top: 8px;
  right: 8px;
}

@media #{$tablet} {
  .panel, .board {
    .open-board, .back-button {
      margin-bottom: -15px;
      margin-right: -5px;
    }

    .fixed-actions {
      width: 200px;
    }

    .carousel {
      .std_nav { display: block; }
      .alt_nav { display: none; }
    }
  }

  .board {
    header {
      position: relative;
    }
  }

  .panel {
    height: 170px;

    .open-board, .back-button {
      position: absolute;
      right: 15px;
      bottom: 21px;
    }
  }
}

@media #{$mobile} {
  .panel, .board {
    .fixed-actions {
      height: 0px;
      top: 105px;
      width: 100%;
    }
    .board-selector{
      width: 173px;
      margin: 0 auto;
      top: 78px;
    }

    .open-board {
      width: 140px;
      top: 80px;
      height: 35px;
      right: 10px;
      font-size: 14px;
    }

    .board-selector {
      display: block;
      margin: 0 auto;
      position: static;
      margin-top: 70px;
    }

    .back-button {
      @include icon-font-before;
      &:before {
        content: $arrow-left-icon;
      }

      width: 110px;
      margin: 0;
      top: -105px;
      right: -10px;
      font-size: 1em;
      padding-top: 10px;
      background: none;
      color: $light-blue;
      padding-right: 0px;
    }
  }
}
