aside#toolbox {
  height: 52px;
  width: 170px;
  border: none;
  position: absolute;
  top: -200px;
  right: 7px;
  opacity: 0;
  transition-property: opacity, top;
  -webkit-transition-property: opacity, top;
  transition-duration: .15s;
  -webkit-transition-duration: .15s;
  transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;

  .mosaic-asset &, .asset &, .comp & {
    top: -42px;
  }

  .asset & {
    right: 0;
    height: 80px;
  }

  .comp & {
    height: 94px;
    right: 15px;
  }

  .action-toolbox {
    position: absolute;
    background-color: white;
    border: 1px solid #C5D2D2;
    font-weight: 500;
    height: 45px;
    width: 230px;
    padding: 8px 18px 0px;
    cursor: pointer;
    right: 0;

    .mosaic-asset & {
      top: 0;
    }

    .asset & {
      top: -12px;
    }

    span.caret {
      float: right;
      margin-top: 8px;
      display: inline-block !important;
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0);
      box-sizing: content-box;

      &:before {
        position: absolute;
        content: '';
        margin: auto;
        width: calc(30px / 3.5);
        height: calc(30px / 3.5);
        background: rgba(0, 0, 0, 0);
        border: calc(20px / 12) solid #000;
        border-right: none;
        border-top: none;
        top: 0;
        bottom: calc(30px / 8);
        left: 0;
        right: 0;
        transform: rotate(-45deg);
        box-sizing: content-box;
        transition-property: border-color;
        transition-duration: .15s;
        transition-timing-function: ease;
      }
    }

    .action-title {
      color: #666E6E;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
    }

    .board-name {
      color: #02937B;
      font-size: 14px;
      line-height: 17px;
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.open {
    opacity: 1;
    top: -38px;

    .action-toolbox {

      &:hover {
        span.caret:before {
          border-color: #02a388;
          transition-property: border-color;
          transition-duration: .15s;
          transition-timing-function: ease;
        }
      }
    }
  }

  @media #{$mobile} {
    display: none;
  }

  @media #{$tablet} {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    display: none;
  }
}

#activeness {
  background: #02A388;
  overflow: visible;

  p {
    font-weight: normal;
    opacity: 1;
    width: auto;
    max-width: 250px;
    padding-right: 12px;
  }

  .icon:after {
    opacity: 1;
  }

  .icon:before {
    opacity: 0;
  }
}
