@import "shared/legacy/modules/board_assets";


.board {
  .board-item {
    margin: 0;
    .board-item-image {
      &.missing, &.offline {
        .board-asset {
          content: url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==")
        }
        @media #{$mobile} {
          height: 230px;
        }
      }

      &.missing {
        .title {
          color: $grey;
        }

        .asset-missing-message {
          color: $grey;
          font-size: 12px;
          background: transparent;
          line-height: 1.0;
          padding: 40% 0;
          @media #{$mobile} {
            padding: 25% 0;
          }
        }
      }
      @media #{$mobile} {
        min-width: 300px;
      }
    }
    .mosaic-details-overlay, .mosaic-select-overlay {
      position: absolute;
      color: $white;
      opacity:0;
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      margin: 0 auto;
      pointer-events: none;
      @media #{$tablet, $no-hover} {
        opacity: 1;
      }
    }

    .mosaic-details-overlay{
      font-size:rem(11);
      bottom: 13px;
      left: 10px;
      right: 7px;
      overflow: hidden;
      padding-top: 40px;
      padding-bottom: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
      background-color: rgba($dark-grey, 0.25);
    }

    .mosaic-select-overlay{
      top: 7px;
      left: 10px;
      right: 0px;
      height: 60px;
      flex-flow: wrap;
    }

    .mosaic-select-overlay-right {
      margin: 15px 15px 0;
      width: 90px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
    }

    .mosaic-collection{ margin: 0 15px; }

    .mosaic-asset-id{
      vertical-align: top;
      margin: 0 15px;
      &:before {
        content: "#";
      }
      @media #{$tablet} {
        opacity: 0;
      }
    }
    &:hover{
      .mosaic-details-overlay, .mosaic-select-overlay {
        opacity: 1;
        z-index: 3;
      }
    }
    .show-mosaic-overlay{
      opacity: 1;
      z-index: 3;
    }

    .mosaic-remove{
      cursor: pointer;
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_trashcan.svg);
      background-position: center;
      border-radius: 2px;
      width: 34px;
      height: 34px;
      pointer-events: auto;

      &:hover {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_trashcan.svg);
        background-repeat: no-repeat;
        height: 34px;
        width: 34px;
      }
    }

    .mosaic-comments-icon {
      position: relative;
      left: 16px;
      pointer-events: auto;

      .count {
        font-size: rem(8);
        padding: 1px 5px;
        line-height: 1.3;
        background: $green;
        color: $white;
        position: relative;
        left: -12px;
        top: -7px;
        border-radius: 8px;
      }
      .icon {
        display: inline-block;
        background-size:contain;
        background-repeat:no-repeat;
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_coment.svg);
        width: 34px;
        height: 34px;
      }
    }

    .mosaic-select-checkbox {
      margin: 15px 15px 0;
      position: relative;
      pointer-events: auto;

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label {
        display: inline-block;
        width: 26px;
        height: 26px;
        vertical-align: top;
        cursor: pointer;
        &:before {
          position: absolute;
          top:3px;
          left:3px;
          content: " ";
          width: 34px;
          height: 34px;
          background-color: transparent;
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_checkbox.svg);
          background-repeat: no-repeat;


        }
      }
      input[type="checkbox"]:checked + label {
        &:before {
          position: absolute;
          top: 3px;
          left: 3px;
          height: 34px;
          width: 34px;
          content: " ";
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_checkbox_selected.svg);
          background-repeat: no-repeat;
        }
      }
    }

    .play {
      color: $white;
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/icon-play.svg);
      background-repeat: no-repeat;
      background-size: 14px;
      position: absolute;
      background-color: white;
      width: 28px;
      height: 28px;
      padding: 5px;
      background-position: center;
      top: auto;
    }
  }

  .asset-container span:first-child {
    float: left;
  }

  .asset-container .board-item {
    float: left;
  }
  .asset-grid.grid.show-details .has-comments a.comments-icon span.count {
    display: inline;
    padding: 1px 5px;
  }
  .asset-grid.grid.show-details .has-comments .comments-preview {
    display: none;
  }

  .board-item-content .remove {
    background-color: rgba(0,0,0,.5);
    background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/close_lightgrey.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center;
    color: $some-grey;
    border-radius: 2px;
    width: 28px;
    text-align: center;
    margin: 0;
    top: 15px;
    right: 15px;

    &:hover {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_close_x_hover.svg);
    }
  }

  .asset-grid.grid {
    @include checkbox;
    // Added url for checkbox icons to fix them for overlay board page on srp
    .checkbox {
      background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/checkboxes/checkbox_unselected.svg);
      &:hover {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/checkboxes/checkbox_hover.svg);
      }
    }
    .checkbox-selected {
      .checkbox {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/checkboxes/checkbox_selected.svg);
      }
    }

    &.show-details .board-item-image{
      height: 280px;
      line-height: 280px;
    }
    .asset-container {
      margin: 0 10px;
    }
    .board-item {
      margin: 0 -1px -1px 0;
    }
    .board-item-image {
      margin: 10px;
      width: auto;
    }
    .board-item-content {
      color: $some-grey;
      background: rgba(255,255,255,0);
      border-radius: 0;
      border: 1px solid white;
      padding: 0;
      transition: background 0.2s ease-out;

      .remove {
        top: 0;
        right: 0;
      }
    }
    .comments-preview {
      line-height: 1.4;

      .comment-text {
        vertical-align: top;
        padding-left: 24px;
        max-width: 245px;
        &.has-more {
          vertical-align: top;
          max-width: 200px;
        }
      }
      .comment-count {
        vertical-align: top;
        margin-top: -2px;
        padding: 2px 5px;
        background: $grey;
        color: white;
      }
    }

    .info-strip {
      background: $light-grey;
      margin-bottom: 0;
      .asset-info {
        border-bottom: 1px solid $grid-grey;
        margin: 0 15px;
        font-size: 14px;
        height: 40px;
        padding: 10px 0;
        border-bottom: 1px solid rgba(255, 255, 255, .2);

        .license-type {
          padding: 0;
        }

        .asset-id {
          &::before { content: '#' }
        }
      }
      .details{
        margin: 0 10px;
        font-size: 11px;
        .text {
          height: 27px;

        }
      }
      > .artist,
      > .collection {
        margin: 0 10px;
        font-size: 10px;
      }
      .collection {
        margin-bottom: 0;
      }

    }
    .selection-comments {
      position: relative;
      background: $light-grey;
      padding: 10px 15px;
      height: 40px;
      .comments-icon {
        position: absolute;
        text-align: center;
        margin-left: 0;
        bottom: auto;
        color: white;

        .count {
          font-size: 10px;
          padding: 1px 0px;
          line-height: 1.3;
          background: $green;
          color: white;
          left: 13px;
          top: -3px;
        }
        .icon:before {
          display: block;
          background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/boards/icon_comments_white.svg);
          width: 18px;
          height: 17px;
          content: " ";
          top: auto;
          left: auto;
        }
      }
      .checkbox {
        background-color: $white;
        bottom: 0;
        right: 0;
      }
    }
  }
}
