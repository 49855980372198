section.set-list {

  .content.loading {
    opacity: 0.3;
  }

  li.curated-set-item {
    height: auto;
    margin: 15px 25px;
    border: 1px solid $india_gray;

    @media #{$tablet} {
      padding: 25px;
      margin: 0;
      border: none;
      border-bottom: 1px solid $india_gray;
    }

    img {
      position: absolute;
      display: inline-block;
      width: 112px;
      height: 112px;
      border-right: 1px solid $india_gray;
      object-fit: contain;

      @media #{$tablet} {
        border: 1px solid $india_gray;
      }
    }

    .no-image {
      position: absolute;
      display: inline-block;
      width: 112px;
      height: 112px;
      background: $background-color;
      border-right: 1px solid $india_gray;
      padding: 45px 20px;
      line-height: normal;
      font-weight: 100;
      font-size: 10px;
      text-align: center;
      color: $india_gray;

      @media #{$tablet} {
        border: 1px solid $india_gray;
      }
    }

    .set-title-container, .button-container {
      display: inline-block;
      height: 92px;
    }

    .set-published-state, .set-title-container {
      margin-left: 112px;
    }

    .set-title-container {
      padding: 30px 0px 30px 20px;

      @media only screen and (min-width: 1132px) {
        max-width: 60%;
      }

      @media only screen and (max-width: 1131px) {
        max-width: 50%;
      }

      @media only screen and (max-width: 916px) {
        max-width: 40%;
      }

      @media #{$tablet} {
        padding: 0px 25px;
        height: auto;
        max-width: 100%;
      }

      @media #{$mobile} {
        min-height: 112px;
      }

      .set-title {
        font-size: 1.4em;
        color: $delta-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        @media #{$mobile} {
          white-space: normal;
        }
      }

      .divider {
        margin: 0px 10px;
        @media only screen and (max-width: 499px) {
          display: none;
        }
      }

      .set-created-by, .set-last-updated-date {
        display: inline-block;
      }

      .set-created-by {
        margin-top: 2px;
      }

      .mobile-published-state {
        display: none;
        @media #{$tablet} {
          display: block;
        }
      }
    }

    .button-container {
      font-size: .8em;
      float: right;
      padding: 30px 10px;

      @media #{$tablet} {
        padding: 10px 20px;
        height: auto;
        display: block;
        margin-top: 14px;
        float: none;
        margin-left: 120px;
      }

      @media #{$mobile} {
        padding: 0px;
        margin-left: 0;
        margin-top: 17px;
      }

      button {
        font-size: 1em;
        width: 140px;
        margin: 0px 10px 0px 0px;

        @media #{$mobile} {
          width: auto;
          padding-left: 32px;
          padding-right: 32px;
          margin: 0px 20px 0px 0px;
        }
      }

      button.view-gallery {
        background: $white;
        border: 1px solid $echo_gray;
        color: $echo_gray;
        width: 120px;

        &:hover {
          color: $charlie_gray;
          border: 1px solid $charlie_gray;
        }
      }

    }

    .set-published-state {
      display: inline-block;
      height: 20px;
      background: $background-color;
      width: calc(100% - 112px);
      padding: 1px 20px;
      font-size: 1em;
      border-top: 1px solid $india_gray;
      @media #{$tablet} {
        display: none;
      }

      a {
        color: $delta_color;
        float: right;
        display: none;
      }
    }
  }
}
