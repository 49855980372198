@import "shared/legacy/modules/common_header";
@import "shared/legacy/modules/board_header";

.board .board-content header {
  min-height: 64px;

  @media #{$mobile} {
    height: 175px;
    font-size: 14px;
    padding: 20px;

    &.hide-search-within {
      height: 175px;

      .fixed-actions {
        top: 117px;
      }
    }
  }

  .panel-visible-button {
    margin-left: 77px !important;
  }

  .button-container {
    display: inline-block;
    min-height: 40px;
    vertical-align: top;

    @media #{$mobile} {
      display: block;
      width: 160px;
    }

    button {
      display: inline-block;
      &.share, &.add-image {
        @extend .secondary-cta;
        background-color: transparent;
        border: 1px solid $shale-grey;
        box-shadow: none;
        color: $light-grey;
        font-size: 16px;
        height: 40px;
        line-height: 1.0;
        margin-bottom: 0;
        margin-left: 13px;
        padding-bottom: 0;
        padding-top: 0;
        width: 140px;

        @media #{$mobile} {
          font-size: 14px;
          margin-left: 0px;
          width: 160px;
        }
      }
      &.add-image {
        display: none;
        @media #{$large-up} {
          display: inline-block;
        }
        &:before {
          display: none;
          content: "";
        }
      }
    }

    .add-search {
      margin-top: 10px;

      @media #{$medium-up} {
        margin-top: 0;
      }

      .add-image {
        &.show-search-box {
          display: none;
        }
      }

      form {
        display: block;
      }

      input {
        background: $white;
        border: 1px solid $some-grey-border;
        box-shadow: 0 6px 5px -5px rgba($dark-grey, .05);
        color: black;
        font-size: 16px;
        margin: 0;
        outline: none;
        padding-left: 30px;
        padding-right: 20px;
        position: absolute;
        width: 160px;

        @media #{$medium-up} {
          margin-left: 13px;
          padding-left: 35px;
          padding-right: 10px;
          width: 210px;
        }

        @media #{$large-up} {
          width: 250px;
        }
      }

      .search-box {
        visibility: visible;
        @media #{$large-up} {
          visibility: hidden;
        }
        &.show-search-box {
          visibility: visible;
        }
      }

      .search-icon {
        background-image: url(~unisporkal-styles/stylesheets/unisporkal_engine/images/istock/icons/search_icon.svg);
        background-repeat: no-repeat;
        background-size: 30px;
        color: $shale-grey;
        display: block;
        outline: none;
        left: 0px;
        position: absolute;
        top: 4px;

        @media #{$medium-up} {
          left: 18px;
          position: absolute;
          top: 4px;
        }

        &::before {
          display: none;
        }
      }

      .spinner {
        left: -3px;
        @media #{$medium-up} {
          left: 15px;
        }
      }

      &.search-null-board {
        display: block;
      }
    }
  }

  .fixed-actions {
    @media #{$mobile} {
      top: 70px;

      .button {
        font-size: 14px;
        left: 0;
        margin: 0 auto;
        right: 0;
        top: 45px;
      }
    }
  }

  .take-tour {
    display: none; // remove line to enable button
    padding: 6px 0 0 15px;
    vertical-align: top;

    &:before {
      content: none;
    }
  }
}

/* Header ribbon */
.panel, .board {
  header {
    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media #{$mobile} {
        flex-direction: column;
      }

      .add-search {
        @media #{$mobile} {
          margin-top: 8px !important;

          .search {
            position: relative !important;
          }
        }
      }

      .fixed-actions {
        display: inline-block;
        height: 40px;
        position: relative;

        @media #{$mobile} {
          display:flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: center;
          align-items: center;
          top: 70px !important;
        }

        a.open-board, a.back-button {
          border: 1px solid $shale-grey;
          border-radius: 5px;
          box-shadow: none;
        }

        a.back-button {
          bottom: 0;
          font-weight: 100;
          line-height: 1.0;
          position: relative;

          @media #{$mobile} {
            padding-right: 30px;
            top: -25px !important;
          }

          @media (min-device-width: 480px) and (max-device-width: 740px) and (orientation: landscape) {
            right: -25px;
          }
        }
      }
    }
  }
}
